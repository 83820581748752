import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["name", "icon"]

  connect() {
    this.update()
  }

  update() {
    if (this.hasIconTarget) {
      this.iconTarget.innerHTML = this.nameTarget.value
    }
  } 
}
