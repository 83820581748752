import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['interval', 'frequency', 'months', 'days', "onSameDay"]

  connect() {
    this.changeFrequency()
  }

  // Set periodicity fields on add task, new maintenance plan form
  changeFrequency() {
    const frequency = this.frequencyTarget.value;

    if (frequency == 'DAILY') {
      this.intervalTarget.classList.add('d-none');
      this.intervalTarget.disabled = false;
    } else {
      this.intervalTarget.classList.remove('d-none');
      this.intervalTarget.disabled = false;
    }

    if (this.hasDaysTarget) {
      if (frequency == 'DAILY') {
        this.daysTarget.classList.remove('d-none');
      } else {
        this.daysTarget.classList.add('d-none');
      }
    }

    if (this.hasMonthsTarget) {
      if (frequency == 'YEARLY') {
        this.monthsTarget.classList.add("d-none")
      } else {
        this.monthsTarget.classList.remove("d-none")
      }
    }

    if (this.hasOnSameDayTarget) {
      if (frequency == 'MONTHLY' || frequency =='YEARLY') {
        this.onSameDayTarget.classList.remove("d-none")
      } else {
        this.onSameDayTarget.classList.add("d-none")
      }
    }

  }
}
