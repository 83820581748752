import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["password", "showEye"];

  togglePasswords() {
    for (let field of this.passwordTargets) {
      field.type = field.type === "password" ? "text" : "password"
    }

    this.showEyeTargets.forEach( (t) => {
      t.classList.toggle('fa-eye');
      t.classList.toggle('fa-eye-slash')
    })
  }
}
