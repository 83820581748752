import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    "input",
    "technicianContainer",
    "managerSelect",
    "managerContainer",
    "actAsTechnician"
  ]

  connect() { this.select() }

  select() {
    const value = this.inputTarget.value

    const managerInput = this.element.querySelector("input.autocomplete__input")

    if (['subcontractor', 'technician'].includes(value)) {
      this.technicianContainerTarget.classList.remove("d-none")
      this.managerContainerTarget.classList.add("d-none")
      this.managerSelectTarget.disabled    = false
      this.actAsTechnicianTarget.disabled  = false
      this.managerSelectTarget.required    = true
      managerInput.required                = true
    } else if(value == 'manager') {
      this.technicianContainerTarget.classList.add("d-none")
      this.managerContainerTarget.classList.remove("d-none")
      this.managerSelectTarget.disabled    = true
      this.actAsTechnicianTarget.disabled  = false
      this.managerSelectTarget.required    = false
      managerInput.required                = false
    } else {
      this.technicianContainerTarget.classList.add("d-none")
      this.managerContainerTarget.classList.add("d-none")
      this.managerSelectTarget.disabled    = true
      this.actAsTechnicianTarget.disabled  = true
      this.managerSelectTarget.required    = false
      managerInput.required                = false
    }
  }
}
