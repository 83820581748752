import BaseNotificationController from './baseNotification_controller'


export default class extends BaseNotificationController {
  static targets = ['container', 'list', 'allread', 'empty', 'count', 'unread']

  // NAVBAR NOTIFICATIONS

  // Show/Hide the notifications list in navbar
  toggle() {
    this.containerTarget.classList.toggle('d-none')
    if (!this.containerTarget.classList.contains('d-none')) {
      // Removing the green from "All read"
      this.allreadTarget.classList.remove('allread')

      // Empty the container and show the spinner
      this.listTarget.innerHTML = ''
      this.listTarget.appendChild(this._showSpinner())

      // Get the last 5 notifications
      this._buildGetRequest('/notifications')
        .then(response => response.json())
        .then((data) => {
          if (Object.entries(data).length > 0) {
            this.listTarget.innerHTML = ''
            Object.entries(data).forEach((notification) => {
              const formattedNotification = this._insertNavNotification(notification[1])
              this.listTarget.appendChild(formattedNotification)
            })
          } else {
            // If there's no notifications clone the div with no notification text and insert it
            const noNotifications = this.emptyTarget.cloneNode(true)
            noNotifications.classList.remove('d-none')
            setTimeout(() => {
              this.listTarget.innerHTML = ''
              this.listTarget.appendChild(noNotifications)
            }, 500)
          }
        })
    }
  }

  // Mark all the notifications as read
  // The fetch is a remote: true in the link_to
  readAll(event) {
    event.currentTarget.classList.add('allread')

    const displayedNotifications = this.listTarget.childNodes
    displayedNotifications.forEach(notif => notif.classList.remove('unread') )

    const counter = this.countTarget
    counter.dataset.unread = 0
    counter.classList.add('d-none')
    document.title = `${counter.dataset.title}`
  }
}
