import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['endDateWrapper', 'endDateField', 'toggle'];

  connect() {
    this.active = this.data.get("active") == "true"
    if (!this.active) this.deactivate()
  }

  // toggle(e) {
  //   e.preventDefault();
  //   this.element.classList.toggle('flexbox-space-between');
  //   this.endDateFieldTarget.closest('.form-group').classList.toggle('d-none');
  //   //   this.endDateFieldTarget.disabled = !this.endDateFieldTarget.disabled
  //   if(this.endDateFieldTarget.closest('.form-group').classList.contains('d-none')) {
  //       this.endDateFieldTarget.value = "";
  //   } else {
  //       const date = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  //       this.endDateFieldTarget.value = `${String(date.getDate).length < 2 ? '0' + date.getDate() : date.getDate()}/`+
  //                                       `${String(date.getMonth() + 1).length < 2 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}/`+
  //                                       `${date.getFullYear()}`;
  //   }

  //   this.toggleTargets.forEach((target) => {
  //       target.classList.toggle('d-none');
  //   })
  // }

  toggle(e) {
    e.preventDefault()
    this.active ? this.deactivate() : this.activate()
    this.active = !this.active
  }

  activate() {
    this.element.classList.add('flexbox-space-between')
    this.toggleTarget.classList.add('d-none')
    this.endDateWrapperTarget.classList.remove('d-none')
    this.endDateFieldTarget.disabled = false
  }

  deactivate() {
    this.element.classList.remove('flexbox-space-between')
    this.toggleTarget.classList.remove('d-none')
    this.endDateWrapperTarget.classList.add('d-none')
    this.endDateFieldTarget.disabled = true
  }
}
