import { Controller } from 'stimulus'
import { getRequest } from '@react/helpers/fetch'
import { csrfToken }  from '@react/helpers/fetch'

export default class extends Controller {
  static targets = ['controls', 'container']

  connect() {
    this.markers    = JSON.parse(this.data.get('markers'))
    if (this.data.has('vehicle-markers')) {
      this.vehicleMarkers = JSON.parse(this.data.get('vehicle-markers'))
      this.allMarkers = this.markers.concat(this.vehicleMarkers)
    } else {
      this.allMarkers = this.markers
    }
    this._renderMap()
    this._renderMarkers()
    this._resetZoom()
    this._drawRoutes()
  }

  clickIcon = e => {
    const record = e.currentTarget.dataset.record
    this._showMarker(record)
  }

  clickCard = e => {
    const card   = e.currentTarget.closest(".small_cards__item")
    const record = card.dataset.record

    document.querySelectorAll('.selected-card').forEach(smallCard =>
      smallCard.classList.remove('selected-card')
    )
    card.classList.add('selected-card')

    const marker = this.map.markers.find(marker => marker.details == record)
    this.map.hideInfoWindows()
    if(!marker) return

    marker.infoWindow.open({
      anchor:      marker,
      map:         this.map,
      shouldFocus: true
    })
  }

  showFocus() {
    if(!this.data.has("refresh")) return
    if(document.getElementById("map-control")) return

    const id       = "map-control"
    const position = "TOP_CENTER"
    const content  = `
      <div data-action="click->map#search">
        <i class="fas fa-redo text-blue"></i>
        &nbsp;
        ${I18n.t("map.search")}
      </div>
    `

    this.map.addControl({ id, content, position })
  }

  search() {
    const url    = new URL(window.location.href)
    this.controlsTarget.innerHTML =
    `
      <div class="loading-spinner-container">
        <div id="loading-spinner"></div>
      </div>
    `

    this.map.removeControl(this.map.controls[0])
    url.searchParams.set("bounds", this.map.getBounds().toUrlValue())
    url.searchParams.set("center", this.map.getCenter().toUrlValue())
    history.replaceState(history.state, document.title, url.href)
    const path = url.pathname + ".json" + url.search
    getRequest(path)
    .then(data => {
      this.controlsTarget.innerHTML = data.html
      reloadReact(this.controlsTarget)
      const authentitcityToken = this.controlsTarget.querySelector("input[name='authenticity_token'")
      authentitcityToken.value = csrfToken()
      this.allMarkers = data.markers
      this._renderMarkers()
    })
  }

  _showMarker(record) {
    const marker = this.map.markers.find(marker => marker.details == record)
    this.map.hideInfoWindows()
    if(!marker) return

    marker.infoWindow.open({
      anchor:      marker,
      map:         this.map,
      shouldFocus: true
    })
  }

  _renderMarkers() {
    this.map.removeMarkers()
    this.markers.forEach(marker => {
      marker.click = e => {
        const card = document.getElementById(marker.card)
        if (card) {
          unselectCards()
          card.querySelector(".small_cards__item").classList.add('selected-card')
          card.scrollIntoView({ block: "center", behavior: "smooth" })
        }
      }
    })

    this.map.addMarkers(this.allMarkers)
  }

  _resetZoom () {
    if (this.allMarkers.length === 0) {
      this.map.setZoom(2)
    } else if (this.allMarkers.length === 1) {
      // Because setCenter is not working for now
      this.map.fitLatLngBounds(this.allMarkers)
      window.setTimeout(() => { this.map.setZoom(14) }, 500)
    } else {
      this.map.fitLatLngBounds(this.allMarkers)
    }
  }

  _drawRoutes () {
    if(this.data.get('showRoutes') === 'true' && this.markers.length > 1) {
      const markers  = [...this.markers]
      if (this.data.get("return") === 'true') markers.push(markers[0])

      let currentLat = this.markers[0].lat
      let currentLng = this.markers[0].lng

      for(let i = 1; i < markers.length; i++) {
        const marker = markers[i]
        const color  = marker.color || '#131540'
        this.map.drawRoute({
          origin        : [currentLat, currentLng],
          destination   : [marker.lat, marker.lng],
          travelMode    : google.maps.TravelMode.DRIVING,
          strokeColor   : color,
          strokeOpacity : 0.6
        })
        currentLat = marker.lat
        currentLng = marker.lng
      }
    }
  }

  _renderMap() {
    this.map = new GMaps({
      el: this.containerTarget,
      lat: 0,
      lng: 0,
      dragend: this.showFocus.bind(this),
      zoom_changed: this.showFocus.bind(this),
      styles: [
        { featureType: 'administrative', elementType: 'labels.text.fill', stylers: [{ color: '#444444' }] },
        { featureType: 'landscape', elementType: 'all', stylers: [{ color: '#f2f2f2' }] },
        { featureType: 'poi', elementType: 'all', stylers: [{ visibility: 'on' }] },
        { featureType: 'poi', elementType: 'geometry.fill', stylers: [{ saturation: '-100' }, { lightness: '57' }] },
        { featureType: 'poi', elementType: 'geometry.stroke', stylers: [{ lightness: '1' }] },
        { featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'off' }] },
        { featureType: 'road',elementType: 'all', stylers: [{ saturation: -100 }, { lightness: 45 }, { visibility: 'simplified' }] },
        { featureType: 'road.highway', elementType: 'all', stylers: [{ visibility: 'simplified' }] },
        { featureType: 'road.arterial', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
        { featureType: 'transit', elementType: 'all', stylers: [{ visibility: 'off' }] },
        { featureType: 'transit.station.bus', elementType: 'all', stylers: [{ visibility: 'on' }] },
        { featureType: 'transit.station.bus', elementType: 'labels.text.fill', stylers: [{ saturation: '0' }, { lightness: '0' }, { gamma: '1.00' }, { weight: '1' }] },
        { featureType: 'transit.station.bus', elementType: 'labels.icon', stylers: [{ saturation: '-100' }, { weight: '1' }, { lightness: '0' }] },
        { featureType: 'transit.station.rail', elementType: 'all', stylers: [{ visibility: 'on' }] },
        { featureType: 'transit.station.rail', elementType: 'labels.text.fill', stylers: [{ gamma: '1' }, { lightness: '40' }] },
        { featureType: 'transit.station.rail', elementType: 'labels.icon', stylers: [{ saturation: '-100' }, { lightness: '30' }] },
        { featureType: 'water', elementType: 'all', stylers: [{ color: '#d2d2d2' }, { visibility: 'on' }] }
      ]
    })
  }
}
