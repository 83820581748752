import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "site", "material" ]

  initialize() {
    this.fetchParams = {
      credentials: 'same-origin',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }
    this.fetchUrl = this.data.get("route")
  }

  change_site() {
    const siteId = this.siteTarget.value
    if(!siteId) return

    fetch(this.fetchUrl + `?id=${siteId}`, this.fetchParams)
    .then(response => response.json())
    .then(data => {
      this._removeOptions()
      data.forEach(material => this._createOption(material))
    })
    .catch(error => alert('Oops, there was an error'))
  }

  // Private Methods

  _removeOptions() {
    this.materialTarget.querySelectorAll("option").forEach(option => {
      if (option.value) option.remove()
    })
  }

  _createOption(material) {
    const newMaterial     = document.createElement("option");
    newMaterial.value     = material.id;
    newMaterial.innerText = material.name;
    this.materialTarget.append(newMaterial)
  }
}
