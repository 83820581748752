import { DirectUpload } from "@rails/activestorage"
import {
  findElement,
  removeElement,
  insertAfter
} from "../../application/helpers/helpers"

const createDirectUpload = (file, url, controller) => (
  new DirectUpload(file, url, controller)
)

export class DirectUploadController {
  constructor(source, file, form) {
    this.directUpload = createDirectUpload(file, source.url, this)
    this.source       = source
    this.file         = file
    this.form         = form
  }

  start() {
    if (this.form) {
      this.form.querySelectorAll('[type=submit]').forEach(submit => {
        submit.disabled = true
      })
    }

    this.file.controller = this
    this.hiddenInput     = this.createHiddenInput()
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput)
        this.emitDropzoneError(error)
      } else {
        this.hiddenInput.value = attributes.signed_id
        this.emitDropzoneSuccess()
      }
    })
  }

  createHiddenInput() {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = this.source.inputTarget.name
    insertAfter(input, this.source.inputTarget)
    return input
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr)
    this.emitDropzoneUploading()
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr
    this.xhr.upload.addEventListener("progress", event => this.uploadRequestDidProgress(event))
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element
    const progress = (event.loaded / event.total) * 100
    findElement(this.file.previewTemplate, ".dz-upload").style.width = `${progress}%`
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING
    this.source.dropZone.emit("processing", this.file)
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR
    this.source.dropZone.emit("error", this.file, error)
    this.source.dropZone.emit("complete", this.file)
  }

  emitDropzoneSuccess() {
    if (this.form) {
      this.form.querySelectorAll('[type=submit]').forEach(submit => {
        submit.disabled = false
      })
    }

    this.file.status = Dropzone.SUCCESS
    this.source.dropZone.emit("success", this.file)
    this.source.dropZone.emit("complete", this.file)
  }
}
