import { Controller } from 'stimulus'
import { jsRequest, pathname } from "@react/helpers/fetch"

export default class extends Controller {
  static targets = ['input']

  select() {
    const material = this.inputTarget.value
    if (!material) return

    const path     = '/maintenance/tasks/get_meters'
    const params   = { material }
    if (this.data.has("plan")) params.plan = true
    const format   = 'js'
    const url      = pathname({ path, params, format })
    jsRequest(url)
  }
}
