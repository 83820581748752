// data-controller="maintenance--workorders--checkbox-preview"
// data-maintenance--workorders--checkbox-preview-stimulus-data
// data-target="maintenance--workorders--checkbox-preview.target"
// data-action="maintenance--workorders--checkbox-preview#action"

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'checkbox'];

  click(e) {
    this.inputTargets.forEach((input) => {
      input.disabled = !this.checkboxTarget.checked
    })
  }
}
