import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'submit']

  connect() {
    if (!this.data.get('donotdisable')) {
      this.checkboxTarget.disabled = this.checkboxTarget.checked
    }
  }

  clicked() {
    if (this.checkboxTarget.checked == false) return false
    this.submitTarget.click()
    this.checkboxTarget.disabled = true
  }

  toggle() {
    this.submitTarget.click()
  }
}
