import { Controller } from "stimulus"
import { getRequest, pathname } from "@react/helpers/fetch"

export default class extends Controller {
  static targets = ["id", "quantity", "container", "product", "reference", "unit", "price", "billingCategory"]

  select() {
    const product = this.productTarget.value
    if (!product) return (console.info("no product"))

    const path     = this.data.get("path")
    const format   = "json"
    const supplier = document.querySelector("input[name*='[supplier_id]']")
    const client   = document.querySelector("input[name*='[client_id]']")
    const params   = { product }
    if (supplier && supplier.value) params.supplier = supplier.value
    if (client   && client.value)   params.client   = client.value
    const url      = pathname({ path, format, params })

    getRequest(url).then(response => {
      this.product = response.product
      this._fillData()
      this._replaceInput()
      this.priceTarget.dispatchEvent(new Event("keyup"))
      this.quantityTarget.focus()
    })
  }

  _fillData() {
    const quillBox     = this.element.querySelector(".ql-editor")
    quillBox.innerHTML = this.product.html

    this.idTarget.value        = this.product.id
    this.referenceTarget.value = this.product.ref_product_brand
    this.unitTarget.value      = this.product.unit
    if (this.hasBillingCategoryTarget) {
      this.billingCategoryTarget.value = this.product.billing_category_id
    }
    if (this.data.get("purchase") == "true") {
      this.priceTarget.value = this.product.purchase_price
    } else {
      this.priceTarget.value = this.product.sale_price
    }
  }

  _replaceInput() {
    this.containerTarget.innerHTML = `
      <a data-toggle="modal"
         data-target="#remote-modal"
         data-route="/products/${this.product.id}"
         data-action="click->modal#show" href=""
      >
        <input type="text" id="" value="${this.product.name}"
          class="form-control pointer"
          disabled
        >
      </a>
    `
  }
}
