// To put in the namespace Maintenance for Orga sake
import { Controller } from 'stimulus'

export default class extends Controller {
  open(event) {
    var label_sign = null;
    const sign = event.currentTarget.dataset.sign.trim()
    if(event.currentTarget.dataset.label !== null){
      label_sign = event.currentTarget.dataset.label
    }
    const formulaNode      = document.getElementById("formula_formula")
    const formulaLabelNode = document.getElementById("formula_formula_label")
    const answer           = formulaNode.value

    if (sign === 'back') {
      if (answer[answer.length - 1] === '}') {
        formulaNode.value  = answer.slice(0, answer.lastIndexOf('{'))
      } else if (answer[answer.length - 1] === ']') {
        formulaNode.value = answer.slice(0, answer.lastIndexOf('['))
      } else {
        formulaNode.value = answer.slice(0, -1)
      }
    } else if (sign[0] == '{') {
      formulaNode.value = answer + sign + "/" + sign

      const new_label_answer = formulaLabelNode.value + label_sign
      formulaLabelNode.value = new_label_answer
    } else {
      formulaNode.value = answer + sign
    }
    formulaLabelNode.value = formulaNode.value
  }

  clean() {
    const formula      = document.querySelector('.formula-value')
    const result       = document.getElementById('result')
    const validFormula = document.getElementById('valid-formula')
    const formulaNode  = document.getElementById('formula_formula')

    formula.value          = ''
    result.value           = ''
    formulaNode.value      = ''
    validFormula.innerHTML = ''
  }
}
