import { Controller } from "stimulus"
import { roundNumber, formatNumber } from "@react/quotes/form/helpers"

export default class extends Controller {
  static targets = [
    "quantity",
    "price",
    "vat",
    "importCoefficient",
    "importCoefficientOutput",
    "discount",
    "subtotalNotDiscounted",
    "subtotalDiscounted",
    "subtotal",
    "storeSubtotal",
    "storeVat",
    "updateTotals"
  ]

  initialize () {
    this.calculate = this.calculate.bind(this)
  }

  // Give Cocoon time to insert the html
  connect() {
    setTimeout(this.calculate, 50)
  }

  calculate() {
    this._setVatRate()
    this._setVariables()
    this._storeTotals()
    this._calculateTotal()
    this._setImportCoefficient()
  }

  // This method is a shortcut to create a new line when submiting the quantity
  // on a product line
  newLine(e) {
    if (e.key == "Enter") {
      e.preventDefault()
      e.stopPropagation()
      const button = document.querySelector("[data-association-insertion-node]")
      if (button) {
        button.click()
        setTimeout(() => {
          const newProductInput = document.querySelector(".row .autocomplete__input")
          if (newProductInput) newProductInput.focus()
        }, 300)
      }
    }
  }

  _setImportCoefficient() {
    if (!this.hasImportCoefficientOutputTarget || !this.hasImportCoefficientTarget) return
    this.importCoefficientOutputTarget.innerText = formatNumber(this.subtotal * this.importCoefficientTarget.value)
  }

  _setVatRate() {
    if (this.vatTarget.value) return

    if (document.querySelector("[name*='[site_id]'"))     this._setSiteVatRate()
    if (document.querySelector("[name*='[supplier_id]'")) this._setSupplierVatRate()
  }

  _setSiteVatRate() {
    const dataElement = document.getElementById("asset-print")
    if (!dataElement) return

    const data          = JSON.parse(dataElement.innerText)
    const siteVatRateId = data.site.vat_rate?.id
    if (!siteVatRateId) return
    this.vatTarget.value = siteVatRateId
  }

  _setSupplierVatRate() {
    const data = document.getElementById("suppliers")
    if (!data) return

    const supplierInput = document.querySelector("input[name*='[supplier_id]']")

    const suppliers  = JSON.parse(data.dataset.reactProps).options
    const supplier   = suppliers.find(s => s.id == supplierInput.value)
    if (!supplier || !supplier.vatRateId) return

    this.vatTarget.value = supplier.vatRateId
  }

  _setVariables() {
    this.quantity              = parseFloat(this.quantityTarget.value) || 0
    this.price                 = parseFloat(this.priceTarget.value) || 0
    this.subtotalNotDiscounted = roundNumber(this.quantity * this.price)
    this.subtotalDiscounted    = roundNumber(this.subtotalNotDiscounted * this._discount() / 100)
    this.subtotal              = roundNumber(this.subtotalNotDiscounted - this.subtotalDiscounted)
  }

  _vat() {
    const selectedVat = this.vatTarget.selectedOptions[0]
    if(!selectedVat)                    return 0
    if(!selectedVat.dataset.percentage) return 0

    return parseFloat(selectedVat.dataset.percentage) / 100
  }

  _discount() {
    if (!this.hasDiscountTarget) return 0

    return parseFloat(this.discountTarget.value) || 0
  }

  _storeTotals() {
    if (this.hasSubtotalNotDiscountedTarget) {
      this.subtotalNotDiscountedTarget.innerText = formatNumber(this.subtotalNotDiscounted)
    }
    if (this.hasSubtotalDiscountedTarget) {
      this.subtotalDiscountedTarget.innerText = formatNumber(this.subtotalDiscounted * -1)
    }
    if(this.hasSubtotalTarget) {
      this.subtotalTarget.innerText = formatNumber(this.subtotal)
    }
    this.storeSubtotalTarget.value  = this.subtotal
    this.storeVatTarget.value       = this._vat()
  }

  _calculateTotal() {
    const event = new Event('calculate')
    this.updateTotalsTarget.dispatchEvent(event)
  }

  _setSiteVatRatePercentage() {
    const siteSelect = document.querySelector("select[name*='[site_id]']")
    if (!siteSelect) return

    const site = siteSelect.selectedOptions[0]
    if (!site) return

    const vatRateId = site.dataset.vat
    if (!vatRateId) return
  }
}
