import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["content", "checkbox", "details"]

  connected() {
    this.toggle()
  }

  toggle() {
    if (this.checkboxTarget.checked) {
      this.contentTarget.classList.remove("d-contents")
      this.contentTarget.classList.add("d-none")
      this.detailsTarget.classList.add("d-none")
      this.contentTarget.querySelector("select").value = ""
    } else {
      this.contentTarget.classList.add("d-contents")
      this.contentTarget.classList.remove("d-none")
      this.detailsTarget.classList.remove("d-none")
    }
  }
}
