import Compressor from 'compressorjs';
import { Controller } from 'stimulus'

export default class extends Controller {

    static targets = ['fileForm', 'fileField', 'submitField', 'preview'];

    uploadPhoto() {
        if (!( window.File && window.FileReader && window.FileList && window.Blob ) ) {
            alert('The File APIs are not fully supported in this browser.')
        } else {
            this._createSpinner()
            this._readfiles(this.fileFieldTarget.files)
            this._removeSpinner()
        }
    }

    updatePhoto(event) {
        let photoId = event.target.dataset.id;
        document.getElementById(`submit-${photoId}`).click();
    }


    // private

    _createSpinner() {
        if (document.getElementById('photo-spinner') === null) {
            const spin_doc = document.createElement('div')
            spin_doc.classList.add('spinner')
            spin_doc.setAttribute('id', 'photo-spinner')
            if (this.hasPreviewTarget) {
              this.previewTarget.prepend(spin_doc)
            } else {
              document.getElementById('preview').appendChild(spin_doc)
            }
        }
    }

    _readfiles(files) {
        for (var i = 0; i < files.length; i++) {
            this._processfile(files[i])
        }
        this.fileFieldTarget.value = ''
    }

    _processfile(file) {
        if( !( /image/i ).test( file.type ) )
        {
            alert( 'File '+ file.name +' is not an image.' )
            document.getElementById('photo-spinner').outerHTML = ''
            return false
        } else {
            const controller = this
            new Compressor(file, {
              quality:    0.6,
              maxHeight: 1024,
              maxWidth:  1024,
              success(result) {
                controller._appendCompressedImgToInputAndPreview(result)
              },
              error(err) {
                controller._removeSpinner()
              }
            })
        }
    }

    _appendCompressedImgToInputAndPreview(result) {
      const reader  = new FileReader();
      reader.readAsDataURL(result);
      reader.onload = () => {
        const base64data = reader.result;
        const newinput   = document.createElement('input')
        newinput.type    = 'hidden'
        newinput.name    = this.fileFieldTarget.name
        newinput.value   = base64data
        this.fileFormTarget.prepend(newinput) // append to form input field
        this.fileFormTarget.classList.remove('placeholder')

        this._addHiddenFieldAnswerId() // used in custom forms to later identify anchor point

        // used in V1
        if (this.hasSubmitFieldTarget) { this.submitFieldTarget.click() }

        // used in custom forms: submits form after each added photo
        const submitButton = document.getElementById('blueprintAnswerSubmitField')
        if (submitButton) { submitButton.click() }
        // preview is used only if we don't submit the form after each added photo
        if (!submitButton && !this.hasSubmitFieldTarget) {
          const imgPreview = document.createElement('img')
          imgPreview.src = base64data
          if (this.hasPreviewTarget) {
            if (this.previewTarget.tagName == 'IMG') {
              this.previewTarget.src = base64data
            } else {
              this.previewTarget.prepend(imgPreview)
            }
          }
        }
      }
    }

    _addHiddenFieldAnswerId() {
      const hiddenField = document.createElement('input')
      hiddenField.type = 'hidden'
      hiddenField.name = 'field_answer_id'
      hiddenField.value = this.fileFormTarget.id
      this.fileFormTarget.appendChild(hiddenField);
    }

    _removeSpinner() {
      document.getElementById('photo-spinner').remove();
    }
}
