import { Controller } from 'stimulus'
import CableReady     from 'cable_ready'

import { getRequest, bodyRequest, pathname } from "@react/helpers/fetch"

const onError = error => {
  console.error(error);
  alert('Oops, there was an error')
}

export default class extends Controller {

  initialize() {
    this.channel    = "RenderLaterChannel"
    this.uuid       = this.data.get("uuid")
    this.params     = { channel: this.channel, uuid: this.uuid }
  }

  render() {
    this._openModal()
    this.subscription = App.cable.subscriptions.create(this.params, {
      connected: this._connected.bind(this),
      received:  this._received.bind(this)
    })
  }

  disconnect() {
    if (this.subscription) this.subscription.unsubscribe()
  }

  _openModal() {
    const container = document.querySelector("#pdf-remote-modal [data-uuid]")
    container.outerHTML = `
      <div class="modal-pdf-loader" data-uuid=${this.uuid}>
        <p>${I18n.t("pdf_documents.in_preparation")}</p>
        <div class="modal-pdf-loader__dot-container">
          <div class="dot-elastic"></div>
        </div>
      </div>
    `
    closeActiveModal()
    showModal('#pdf-remote-modal')
  }

  _connected() {
    const path   = this.data.get("path")
    const type   = this.data.get("type")
    const params = { type, uuid: this.uuid }

    if (this.data.has("params")) {
      const extraParams = JSON.parse(this.data.get("params"))
      Object.keys(extraParams).forEach(key => params[key] = extraParams[key])
    }

    const url = pathname({ path, params })
    if (this.data.has("body")) {
      const body = JSON.parse(this.data.get("body"))
      bodyRequest({ url, body }).catch(onError)
    } else {
      getRequest(url).catch(onError)
    }
  }

  _received(data) {
    if (document.querySelector("#pdf-remote-modal.show")) {
      if (data.cableReady) {
        CableReady.perform(data.operations)
        // document.getElementById('pdf-render-link').click()
      }
    }
    this.disconnect()
  }
}
