import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'output' ]

  switch(event) {
      event.preventDefault()
      const new_form_part = document.getElementById('addNewResponsible').innerHTML
      const old_form_part = document.getElementById('existingResponsible').innerHTML
      document.getElementById('addNewResponsible').innerHTML   = old_form_part
      document.getElementById('existingResponsible').innerHTML = new_form_part
  }
}
