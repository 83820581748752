import { Controller } from 'stimulus'

const STRING  = "string"
const BOOLEAN = "boolean"
const DATE    = "date"
const INTEGER = "integer"
const FLOAT   = "float"
const OPTIONS = "options"

export default class extends Controller {
  static targets = ['required', 'options', 'special', 'selectType']

  connect() {
    this._setAvailableFunctions()
  }

  changeSelection() {
    const value = this.selectTypeTarget.value
    if (value == this.data.get(BOOLEAN)) {
      this._hideIt(this.requiredTarget)
    } else {
      this._showIt(this.requiredTarget)
    }

    if (value == this.data.get(OPTIONS)) {
      this._showIt(this.optionsTarget)
    } else {
      this._hideIt(this.optionsTarget)
    }
    this._resetSpecial()
    this._setAvailableFunctions()
  }

  // private

  _hideIt(element){
    element.classList.add('d-none')
  }

  _showIt(element){
    element.classList.remove('d-none')
  }

  _resetSpecial(element){
    if(!this.hasSpecialTarget) return

    this.specialTarget.options.selectedIndex = 0
  }

  _setAvailableFunctions() {
    if(!this.hasSpecialTarget) return

    const selected = this.selectTypeTarget.value
    this.specialTarget.options.forEach(option => {
      const notPrompt = !!option.value
      const available = option.dataset.available == selected
      option.disabled = notPrompt && !available
    })
  }
}
