import { Controller } from "stimulus"

export default class extends Controller {

  onChange(e) {
    const category = e.currentTarget.value

    const url = new URL(this.element.dataset[this.data.get("dataset")], window.location.origin)
    if (category) {
      url.searchParams.set("category", category)
    } else {
      url.searchParams.delete("category")
    }
    this.element.dataset[this.data.get("dataset")] = url
  }
}
