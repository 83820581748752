import { Controller } from 'stimulus'

export default class extends Controller {
  timeout = 2000

  connect() {
    this.element.closest("form").addEventListener("formdata", e => {
      setTimeout(this.submit.bind(this), this.timeout)
    })
  }

  submit(e) {
    this.element.reset()
    this.element.querySelectorAll("[type='submit']").forEach(submit => {
      submit.disabled = false
    })
    const dismiss = this.element.querySelector("[data-dismiss='modal']")
    if(dismiss) dismiss.click()
  }
}
