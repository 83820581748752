import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["checkbox"]

  toggleCheck(e) {
    const value = e.currentTarget.checked
    this.checkboxTargets.forEach(checkbox => checkbox.checked = value)
  }
}
