import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "hide", "show", "focus" ]

  // Hide targets are initially Shown
  // Shown targets are initially hidden

  connect() {
    this.data.get('showInit') == "true" ? this._show() : this._hide()
  }

  clickShow(e) {
    e.preventDefault()
    e.stopPropagation()
    this._show()
  }

  clickHide(e) {
    e.preventDefault()
    e.stopPropagation()
    this._hide()
  }

  toggle() {
    this.shown ? this._hide() : this._show()
  }

  _show() {
    this.shown = true
    this.showTargets.forEach(item => item.classList.remove('d-none'))
    this.hideTargets.forEach(item => item.classList.add('d-none'))
    if (this.data.has("grid")) {
      this.showTargets.forEach(item => item.classList.add('d-contents'))
      this.hideTargets.forEach(item => item.classList.remove('d-contents'))
    }
    this.hasFocusTarget && this.focusTarget.focus()
  }

  _hide() {
    this.shown = false
    this.showTargets.forEach(item => item.classList.add('d-none'))
    this.hideTargets.forEach(item => item.classList.remove('d-none'))
    if (this.data.has("grid")) {
      this.showTargets.forEach(item => item.classList.remove('d-contents'))
      this.hideTargets.forEach(item => item.classList.add('d-contents'))
    }
  }
}
