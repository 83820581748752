import { Controller } from 'stimulus'

import SignaturePad   from 'signature_pad'
export { SignaturePad as SignaturePad }

export default class extends Controller {
  static targets = ['canvas', 'input']

  connect() {
    this.signaturePad = new SignaturePad(this.canvasTarget, {
      backgroundColor: 'rgb(255, 255, 255)',
      penColor: 'rgb(0, 0, 0)',
      onEnd: () => {
        this.inputTarget.value = this.signaturePad.toDataURL()
      }
    })

    if (this.data.get('waitForModal')) {
      setTimeout(() => {
        this._resizeCanvas();
        if (this.inputTarget.value) { this.signaturePad.fromDataURL(this.inputTarget.value)};
      }, 300)
    } else {
      this._resizeCanvas()
    }

    this.signaturePad.off()
    this.canvasTarget.dataset.status = 'off'
    this.cachedWidth = this.canvasTarget.offsetWidth

    // Setting initial value
    if (this.inputTarget.value) {
      this.signaturePad.fromDataURL(this.inputTarget.value)
    }
  }

  activatePad(event) {
    if (this.canvasTarget.dataset.status === 'off') {
      this.canvasTarget.dataset.status = 'on'
      this.signaturePad.on()
      event.currentTarget.innerHTML = this.canvasTarget.dataset.disabletext
    } else if (this.canvasTarget.dataset.status === 'on') {
      this.canvasTarget.dataset.status = 'off'
      this.signaturePad.off()
      event.target.innerText = this.canvasTarget.dataset.activatetext
    }
  }

  clearPad() {
    this.signaturePad.clear()
    this.inputTarget.value = ''
  }


  // private

  _resizeCanvas() {
    var ratio     =  Math.max(window.devicePixelRatio || 1, 1)
    this.canvasTarget.width  = this.canvasTarget.offsetWidth * ratio
    this.canvasTarget.height = this.canvasTarget.offsetHeight * ratio
    this.canvasTarget.getContext('2d').scale(ratio, ratio)
  }
}

// Alternative in _markStatus() (to avoid passing an empty string as status hidden field)
// would be inserting or removing this node?
// `<input type="hidden" name="quote[status]" id="quote_status" value="Accepted">`
