import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input']

  reset(e) {
    e.preventDefault()
    e.stopPropagation()
    this.inputTarget.value = ''
  }
}

