import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'form' ]

  toggleEditForm(event) {
    event.preventDefault()
    this.formTarget.classList.toggle('d-none')
    this.formTarget.classList.toggle('d-contents')
  }
}
