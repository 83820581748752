import { Controller } from 'stimulus'
import { jsRequest } from '@react/helpers/fetch'

export default class extends Controller {

  static targets = ['vatInput', 'viesQuery', 'container']

  callViesDatabase(e) {
    e.preventDefault()
    e.stopPropagation()

    this.containerTarget.innerHTML = `<div class="spinner-sm" style="margin: 0 auto;"></div>`
    const url = new URL(this.data.get("route"), location.origin)
    url.searchParams.set('vat_number', this.vatInputTarget.value)
    jsRequest(url)
  }
}
