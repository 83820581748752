import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'input', 'toggler' ]

  toggle() {
    if (this.inputTarget.value == "") {
      this.togglerTargets.forEach(target => target.classList.add("d-none"))
    } else {
      this.togglerTargets.forEach(target => target.classList.remove("d-none"))
    }
  }
}
