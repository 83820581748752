const jsErbCall = showUrl => {
  const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

  let xhr = new XMLHttpRequest();
  xhr.open('GET', showUrl, false);

  // Headers
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
  xhr.setRequestHeader('X-CSRF-Token', csrf);
  xhr.setRequestHeader('dataType', 'script');

  // Eval the .js.erb response when it comes
  xhr.onload = function () {
    let responseObj = xhr.response
    eval(responseObj)
  }

  // Sending the request
  xhr.send()
}

export { jsErbCall }
