import { Controller } from 'stimulus'
// import sanitizeHtml from 'sanitize-html'

export default class extends Controller {

  static targets = ['value']

  initialize() { this.changeHeight = this.changeHeight.bind(this) }

  connect() {
    this.valueTarget.style.minHeight = this.data.get('minHeight') || '4em'
    setTimeout(this.changeHeight, 10)
    this.valueTarget.addEventListener("focus", this.changeHeight)
  }

  changeHeight() {
    this.valueTarget.style.height = this.valueTarget.scrollHeight + 'px'
  }
}
