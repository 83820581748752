import { Controller } from 'stimulus'

import SignaturePad from 'signature_pad'
export { SignaturePad as SignaturePad }

export default class extends Controller {
  static targets = [ 'createbox', 'createbutton', 'duplicateForm', 'destroySectionId', 'duplicateSectionId']

  initialize() {
    const canvasList = document.querySelectorAll('canvas')
    if (canvasList.size === 0) { return }
    canvasList.forEach((canvas) => {
      if (canvas.dataset.created === 'false') {
        // Setting signature pad
        this._resizeCanvas(canvas)
        canvas.dataset.created = 'true'
        var signaturePad = new SignaturePad(canvas, {
          backgroundColor: 'rgba(255, 255, 255, 0)',
          penColor: 'rgb(0, 0, 0)'
        })
        signaturePad.off()

        // Setting initial value
        const signatureHiddenInput = canvas.parentNode.querySelector('input')
        if (signatureHiddenInput.value) { signaturePad.fromDataURL(signatureHiddenInput.value) }

        // Setting input
        signaturePad.onEnd = function() { signatureHiddenInput.value = signaturePad.toDataURL() }

        // Setting buttons
        const activatePadButton = canvas.parentNode.querySelector('.activatepad')
        const clearPadButton    = canvas.parentNode.querySelector('.clearpad')

        activatePadButton.addEventListener('click', (event) => {
          if (canvas.dataset.status === 'off') {
            canvas.dataset.status = 'on'
            signaturePad.on()
            event.target.innerText = canvas.dataset.disabletext
          } else if (canvas.dataset.status === 'on') {
            canvas.dataset.status = 'off'
            signaturePad.off()
            event.target.innerText = canvas.dataset.activatetext
          }
        })
        clearPadButton.addEventListener('click', () => {
          signaturePad.clear()
          signatureHiddenInput.value = ''
        })
        // window.addEventListener('resize', () => { this._resizeCanvas(canvas) })
      }
    })
  }

  toggleEdit(event) {
    event.preventDefault()
    this._hideAllElements('.blueprint-form')
    this._showAllElements('.show-form')
    const infoElement = event.target.closest('a')
    if (infoElement) {
      const toShow = document.getElementById(`${infoElement.dataset.show}`)
      const toHide = document.getElementById(`${infoElement.dataset.hide}`)
      if (toShow) { toShow.classList.remove('d-none') }
      if (toHide) { toHide.classList.add('d-none') }
    }
  }

  toggleOptions(event) {
    const selectedFieldType   = event.target.value
    const dataset             = event.target.closest('select').dataset
    const optionsCommonDiv    = document.getElementById(dataset.selectcommon)
    const optionsDiv          = document.getElementById(dataset.select)
    const optionsPrefillDiv   = document.getElementById(dataset.selectprefill)
    const prefillDiv          = document.getElementById(dataset.prefill)
    const isMandatoryCheckbox = document.getElementById(dataset.isMandatory)

    optionsCommonDiv.style.display = ['select', 'select_prefill'].includes(selectedFieldType) ? '' : 'none';
    optionsDiv.style.display        = selectedFieldType === 'select' ? '' : 'none';
    optionsPrefillDiv.style.display = selectedFieldType === 'select_prefill' ? '' : 'none';
    prefillDiv.style.display = ["text", "description"].includes(selectedFieldType) ? '' : 'none';

    // Update the "is_mandatory" checkbox
    if (['date', 'date_time', 'static', 'photos', 'page_break'].includes(selectedFieldType)) {
      isMandatoryCheckbox.checked = false;
      isMandatoryCheckbox.disabled = true;
    } else {
      isMandatoryCheckbox.disabled = false;
    }

    const mandatoryCheckboxContainer = isMandatoryCheckbox.closest('.checkbox');
    mandatoryCheckboxContainer.style.display = ['static', 'page_break'].includes(selectedFieldType) ? 'none' : '';
  }

  toggleDuplicateForm() {
     this.duplicateFormTarget.classList.toggle("d-none")
  }

  selectSectionType(event) {
    const sectionInput = document.getElementById('new_section_name')
    if (event.target.value == 'blank_space') {
      sectionInput.classList.add('d-none')
      sectionInput.value = 'blank_space'
    } else {
      sectionInput.classList.remove('d-none')
      sectionInput.value = ''
    }
  }

  toggleExtraOption(event) {
    const extraField = document.getElementById(event.target.dataset.target)
    if (extraField) {
      if (event.target.value == 'choose_extra_option' || event.target.checked) {
        extraField.classList.remove('d-none')
      } else {
        const extraFieldInput = extraField.querySelector('input')
        extraFieldInput.value = ''
        extraField.classList.add('d-none')
      }
    }
  }

  toggleFalseCheckbox(event) {
    const falseCheckbox   = document.getElementById(event.target.dataset.target)
    falseCheckbox.checked = !event.target.checked
  }

  toggleCreateBox(event) {
    event.preventDefault()
    this.createboxTarget.classList.toggle('d-none')
    this.createbuttonTarget.classList.toggle('d-none')
  }

  showPrefilledInput(event) {
    const formTextAreaFieldName = event.target.dataset.formTextAreaFieldName
    const prefillInputTargets = document.querySelectorAll(`[data-name="${formTextAreaFieldName}"]`);
    const selectedIndex = event.target.selectedIndex
    prefillInputTargets.forEach(item => {
      const index = item.getAttribute('index')
      if(index==selectedIndex) {
        item.setAttribute('name', item.dataset.name)
        item.classList.remove('d-none')
      } else {
        item.removeAttribute('name')
        item.classList.add('d-none')
      }
    })
  }

  addSection(event) {
    this._handleSectionModification(event, 'duplicate');
  }

  deleteSection(event) {
    this._handleSectionModification(event, 'destroy');
  }


  // PRIVATE METHODS
  _hideAllElements(cssSelector) {
    document.querySelectorAll(`${cssSelector}`).forEach((form) => { form.classList.add('d-none') })
  }

  _showAllElements(cssSelector) {
    document.querySelectorAll(`${cssSelector}`).forEach((form) => { form.classList.remove('d-none') })
  }

  _resizeCanvas(canvas) {
    var ratio     =  Math.max(window.devicePixelRatio || 1, 1)
    canvas.width  = canvas.offsetWidth * ratio
    canvas.height = canvas.offsetHeight * ratio
    canvas.getContext('2d').scale(ratio, ratio)
  }

  _handleSectionModification(event, action) {
    event.preventDefault();
    const form = this.element.closest("form");
    const sectionId = event.currentTarget.dataset.sectionId;

    if (action === 'duplicate') {
      this.duplicateSectionIdTarget.value = sectionId;
    } else if (action === 'destroy') {
      this.destroySectionIdTarget.value = sectionId;
    }

    if (form.checkValidity()) {
      const submitEvent = new Event('submit', { bubbles: true, cancelable: true });
      form.dispatchEvent(submitEvent);
    } else {
      form.reportValidity();
    }
  }

}
