import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container', 'name', 'button', 'submit']

  activate() {
    if (this.activate) {
      this.buttonTarget.remove()
      this.nameTarget.required = true
      this.nameTarget.disabled = false
      this.submitTarget.value  = I18n.t("calendar.save_filter")
      this.containerTarget.classList.remove("d-none")
    }
  }
}
