import { Controller } from 'stimulus'
import { bodyRequest } from '@react/helpers/fetch'

export default class extends Controller {
  static targets = [ 'checkbox', 'save' ]

  connect() {
    const unchecked = new URL(window.location.href).searchParams.get("unchecked")
    if(!unchecked) return

    const uncheckedResources = unchecked.split(",")
    this.checkboxTargets.forEach(checkbox => {
      const resourceId = checkbox.dataset.resourceId
      checkbox.checked = !uncheckedResources.includes(resourceId)
    })
    this.applyFilter()
  }

  changeManager(e) {
    const checked = e.target.checked
    const managerId = e.target.dataset.resourceId
    document.querySelectorAll(`[data-manager-id='${managerId}']`).forEach((tech) => {
      tech.checked = checked
    })
    this.applyFilter()
  }

  applyFilter() {
    const uncheckedResources = []
    this.checkboxTargets.forEach(checkbox => {
      const resourceId = checkbox.dataset.resourceId
      if (checkbox.checked) {
        this._showResourcesFor(resourceId)
      } else {
        uncheckedResources.push(resourceId)
        this._hideResourcesFor(resourceId)
      }
    })
    const url = new URL(window.location.href)
    url.searchParams.set("unchecked", uncheckedResources)
    history.replaceState(history.state, document.title, url.href)
    this._enableSave()
  }

  save() {
    if(this.saveTarget.disabled) return
    this.saveTarget.disabled  = true
    const unchecked = new URL(window.location.href).searchParams.get("unchecked")
    const url       = this.data.get("saveRoute")
    bodyRequest({ url, body: { unchecked }})
    .then(data => this.saveTarget.innerHTML = I18n.t("calendar.filter_saved"))
  }

  // Private Methods

  _showResourcesFor(resourceId) {
    document.querySelectorAll(`[data-resource='${resourceId}']`).forEach((event) => {
      event.classList.remove('d-none')
    })
    document.querySelectorAll(`[data-resource-id='${resourceId}']`).forEach((event) => {
      event.classList.remove('d-none')
    })
  }

  _hideResourcesFor(resourceId) {
    document.querySelectorAll(`[data-resource='${resourceId}']`).forEach((event) => {
      event.classList.add('d-none')
    })
    document.querySelectorAll(`[data-resource-id='${resourceId}']`).forEach((event) => {
      event.classList.add('d-none')
    })
  }

  _enableSave() {
    if (!this.saveTarget.disabled) return

    this.saveTarget.disabled  = false
    this.saveTarget.innerHTML = I18n.t("calendar.save_filter")
  }
}
