import { Controller }  from "stimulus"
import { roundNumber } from "@react/quotes/form/helpers"

export default class extends Controller {
  static targets = [ "input", "container", "range", "percentage", "amountInput", "amountShow" ]

  connect() {
    this.hide()
    this.setValues()
    this.setMaxAmount()
  }

  hide() {
    this.containerTarget.classList.add("d-none")
    this.rangeTarget.disabled = true
  }

  show() {
    this.rangeTarget.disabled = false
    this.setValues()
    this.containerTarget.classList.remove("d-none")
  }

  changeRange() {
    const percentage = this.rangeTarget.value
    this.data.set("percentage", percentage)
    this.setValues()
  }

  verifyAmount() {
    const newAmount = this.amountInputTarget.value
    const max = this.amountInputTarget.getAttribute('max')
    if (max && parseFloat(max) < newAmount) {
      this.amountInputTarget.value = max
    }
    if (newAmount < 0) {
      this.amountInputTarget.value = 0
    }
  }

  changeAmount() {
    const newAmount = this.amountInputTarget.value
    const total     = roundNumber(this.data.get("total"))
    const percentage = newAmount / total * 100
    this.data.set("percentage", percentage)
    this.rangeTarget.setAttribute('step', 0.000000001)
    this.rangeTarget.value          = percentage
    this.percentageTarget.innerText = `${Math.round(percentage * 100) / 100} %`
  }

  setValues() {
    const percentage    = roundNumber(this.data.get("percentage"))
    const total         = roundNumber(this.data.get("total"))
    const displayAmount = roundNumber(total * percentage / 100)
    const style         = { style: 'currency', currency: this.data.get("currency") }
    const displayTotal  = total.toLocaleString(I18n.locale, style)

    this.percentageTarget.innerText = `${percentage} %`
    this.amountShowTarget.innerText = ` / ${displayTotal}`
    this.amountInputTarget.value    = displayAmount
    this.rangeTarget.value          = percentage
    this.rangeTarget.setAttribute('step', 1)
  }

  setMaxAmount() {
    const total      = roundNumber(this.data.get("total"))
    const percentage = roundNumber(this.data.get("percentage"))
    const amount     = roundNumber(total * percentage / 100)
    this.amountInputTarget.setAttribute('max', amount);
  }
}
