import { Controller } from 'stimulus'
import { jsRequest } from '@react/helpers/fetch'

export default class extends Controller {

  toggleEditForm(event) {
    event.preventDefault();
    const absenceId       = event.target.dataset.absence
    const showContainerId = `card_absence_${absenceId}`
    const editFormId      = `form_absence_${absenceId}`
    document.getElementById(showContainerId).classList.toggle('d-none');
    document.getElementById(editFormId).classList.toggle('d-none');
  }

  submitForm(event) {
    const form = event.target.closest('form');
    const formButtonId = event.target.parentElement.dataset.buttonid
    const formButton = form.querySelector(formButtonId);
    formButton.click()
  }

  fetchAbsencesForUser(e) {
    if (e.target.value.length == 0) return

    const url = new URL(this.data.get("path"), location.origin)
    url.searchParams.set('user_id', e.target.value)
    jsRequest(url)
  }
}
