import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'preventiveTitleSelect',
    'customPreventiveTitle',
    'contractInvoiceSelect',
    'customInvoiceTitle'
  ]

  connect() {
    this.togglePreventiveTitle()
    this.toggleInvoiceTitle()
  }

  // Display a text field to enter a custom value
  togglePreventiveTitle() {
    const select = this.preventiveTitleSelectTarget
    const input  = this.customPreventiveTitleTarget
    this._toggle(select, input)
  }

  toggleInvoiceTitle() {
    const select = this.contractInvoiceSelectTarget
    const input  = this.customInvoiceTitleTarget
    this._toggle(select, input)
  }

  _toggle(select, input) {
    if (select.value === 'custom') {
      input.classList.remove('d-none')
    } else {
      input.classList.add('d-none')
      input.querySelector('input').value = ''
    }
  }
}
