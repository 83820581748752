import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'wrapper', 'input', 'type' ]

  connect() {
    this.change()
  }

  change() {
    const revisionType = this.typeTarget.value
    revisionType == "no" ? this._hideRevision() : this._showRevision()
  }

  _hideRevision() {
    this.inputTargets.forEach(input => {
      input.required = false
      input.value    = ""
    })
    this.wrapperTarget.classList.add('d-none')
  }

  _showRevision() {
    this.inputTargets.forEach(input => input.required = true)
    this.wrapperTarget.classList.remove('d-none')
  }
}
