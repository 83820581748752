import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['list', 'selectoption', 'controller']

  initialize() {
      // Fucking workorder form who initialize the controller several times, spaguetti to prevent it
      this.hasAlreadyBeenInitialized = this.data.get('initialized')
      this.controllerTarget.dataset.workordersBlueprintInitialized = 'true'
  }

  addBlueprint(event) {
      event.preventDefault()
      if (this.hasAlreadyBeenInitialized === 'true') {
          return false
      }
      if (this.selectoptionTarget.value !== '') {
          const new_blueprint = document.createElement('div')
          new_blueprint.classList.add('flexbox-space-between')
          new_blueprint.style.padding = '5px 0px 5px 13px'
          new_blueprint.innerHTML =
      `<div>${this.selectoptionTarget.selectedOptions[0].innerText}</div>
      <i class='fas fa-trash-alt btn btn-danger' data-action="click->workorders-blueprint#deleteReport"></i>
      <input type="hidden" name="workorder[blueprint_ids][]" value="${this.selectoptionTarget.value}">`
          this.listTarget.append(new_blueprint)
      }
  }

  deleteReport(event) {
      if (this.hasAlreadyBeenInitialized === 'true') {
          return false
      }
      event.target.parentElement.outerHTML = ''
  }
}
