const HEADERS = {
  'Content-Type':     'application/json',
  'Accept':           'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  'Cache-Control':    'no-cache'
}

export const csrfToken = () => {
  const meta = document.querySelector('meta[name="csrf-token"]')
  return meta.getAttribute("content")
}

const getHeaders = () => {
  const headers = { ...HEADERS }
  headers['X-CSRF-Token'] = csrfToken()
  return headers
}

export const getRequest = url => {
  if (window.navigator.onLine === false) {
    return alert(I18n.t('errors.messages.offline'))
  }

  return new Promise((resolve, reject) => {
    fetch(url, {
      credentials: 'same-origin',
      headers:      getHeaders()
    })
    .then(response => response.json())
    .then(resolve)
    .catch(reject)
  })
}

export const jsRequest = (url, options = {})  => {
  if (window.navigator.onLine === false) {
    return flashAlert(I18n.t('errors.messages.offline'))
  }
  const { method = 'GET', callback } = options

  const onError = error => {
    flashAlert(I18n.t('errors.messages.oops'))
  }

  const  headers = getHeaders()
  delete headers['Content-Type']
  delete headers['Accept']

  fetch(url, {
    method,
    headers,
    credentials: 'same-origin'
  })
  .then(response => response.text())
  .catch(onError)
  .then(text => {
    eval(text)
    if (callback) callback()
  })
  .catch(onError)
}

export const modalRequest = url => {
  showModal('#remote-modal');
  const spinnerHTML =
    `
      <div class="loading-spinner-container">
        <div id="loading-spinner"></div>
      </div>
    `

  const modal = document.getElementById('remote-modal')
  modal.querySelector('.modal-body').innerHTML = spinnerHTML
  jsRequest(url, { callback: () => reloadReact(modal) })
}

export const bodyRequest = ({ url, body, method = 'POST' }) => {
  return new Promise((resolve, reject) => {
    fetch(url,
      {
        method,
        headers:     getHeaders(),
        credentials: 'same-origin',
        body:        JSON.stringify(body || {})
      }
    )
    .then(response => response.json())
    .then(resolve)
    .catch(reject)
  })
}

export const postRequest = ({ url, body = {} }) => (
  bodyRequest({ method: 'POST', url, body })
)

export const patchRequest = ({ url, body = {} }) => (
  bodyRequest({ method: 'PATCH', url, body })
)

export const deleteRequest = url => bodyRequest({ method: 'DELETE', url })

export const formRequest = ({ method = 'POST', url, form }) => {
  return new Promise((resolve, reject) => {
    const headers = getHeaders()
    delete headers['Content-Type']
    fetch(url,{ method, headers, credentials: 'same-origin', body: form })
    .then(response => response.json())
    .then(resolve)
    .catch(reject)
  })
}

export const pathname = ({ path, format, params = {} }) => {
  const url    = new URL(window.location.origin + path)
  if (format) { url.pathname = url.pathname + `.${format}`}
  Object.keys(params).forEach(key => url.searchParams.set(key, params[key]))
  return url.pathname + url.search
}
