import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['hidden', 'checkbox']

  change(e) {
    const checked = e.target.checked
    this.hiddenTarget.disabled = checked
  }
}
