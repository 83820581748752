import { Controller } from 'stimulus'
import { jsErbCall }  from '@react/helpers/jsErbCall'

export default class extends Controller {

  refreshInterval = 30000

  initialize() {
    this.refresh = this.refresh.bind(this)
  }

  connect() {
    this.timeout = setTimeout(this.refresh, this.refreshInterval)
  }

  refresh() {
    jsErbCall(this.data.get("path"))
  }

  disconnect() {
    clearTimeout(this.timeout)
  }
}
