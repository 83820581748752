import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["fieldContainer", "field", "receiverField", "showEye"];

  api_key      = ''
  fetch_params = {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }
  }

  toggleField() {
    if (this.showEyeTarget.classList.contains('not-show')) {
      this.showEyeTarget.innerHTML = "<i class='fas fa-eye'></i>"
      this.showEyeTarget.classList.remove('not-show')
      this._hideKey()
    } else  {
      this._getAndShowApiKey()
    }
  }

  copyField() {
    const fieldContainer = this.fieldContainerTarget
    fetch('/api_key', this.fetch_params)
      .then(response => response.json())
      .then(data => {
        navigator.clipboard.writeText(data.key).then(() => {
          fieldContainer.setAttribute('tooltip', fieldContainer.dataset.copied)
        }, () => {
          fieldContainer.setAttribute('tooltip', 'Unable to copy')
        })
      })
  }

  refreshKey() {
    if (confirm("Are you sure ? All the applications and services connected on Yuman will lose their access. You'll have to replace the old key with your new API key everywhere.")) {
      fetch('/regenerate_api_key', this.fetch_params)
        .then(response => response.json())
        .then(() => this._getAndShowApiKey())
    }
  }

  _getAndShowApiKey() {
    fetch('/api_key', this.fetch_params)
      .then(response => response.json())
      .then(data => {
        this.api_key = data.key
        this._showKey()
        this.showEyeTarget.setAttribute('tooltip', 'Hide API key')
        this.showEyeTarget.innerHTML = "<i class='fas fa-eye-slash'></i>"
        this.showEyeTarget.classList.add('not-show')
      })
  }

  _showKey() { this.fieldTarget.innerText = this.api_key }
  _hideKey() {
    this.fieldTarget.innerText = '••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••'
    this.api_key = ''
  }
}
