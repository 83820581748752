import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    setTimeout(this.click.bind(this), 200)
  }

  click () {
    const selector = this.data.get("selector")
    const element  = document.querySelector(selector)
    if (element) element.click()
  }
}
