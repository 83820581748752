import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['modal', 'row'];

  showHideDetails(event) {
      const row = event.target.closest('.row');
      const details = document.querySelector(`#${row.id}-details`);
      if (details.classList.contains('hidden')) {
          this._showRow(row);
      } else {
          this._hideRow(row);
      }
  }

  summaryOrDetails(event) {
      const button = event.target;
      let rows = this.rowTargets
      rows.forEach(row => {
          if (button.dataset.mode === 'show') {
              this._showRow(row);
          }
          else if (button.dataset.mode === 'hide') {
              this._hideRow(row);
          }
      })
      if (button.dataset.mode === 'show') {
          button.innerText = button.dataset.summary;
          button.dataset.mode = 'hide';
      } else if (button.dataset.mode === 'hide') {
          button.innerText = button.dataset.details;
          button.dataset.mode = 'show';
      }
  }

  show(event) {
      const route = event.target.closest('[data-route]').dataset.route;
      this._showSpinner();
      this._fetchRoute(route);
  }

  // Private methods

  _showRow(row) {
      const details = document.querySelector(`#${row.id}-details`);
      const triangle = row.querySelector('.arrow-left');
      triangle.classList.add('rotate-90');
      details.classList.remove('hidden');
      row.classList.add('nohover');
  }

  _hideRow(row) {
      const details = document.querySelector(`#${row.id}-details`);
      const triangle = row.querySelector('.arrow-left');
      triangle.classList.remove('rotate-90');
      details.classList.add('hidden');
      row.classList.remove('nohover');
  }

  _showSpinner() {
      const spinnerHTML =
      ` <div class="loading-spinner-container">
          <div id="loading-spinner"></div>
      </div>`;
      this.modalTarget.innerHTML = spinnerHTML;
  }

  _fetchRoute(route) {
      const fetch_params = {
          credentials: 'same-origin',
          headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          }
      }

      fetch(route, fetch_params)
          .then(this._handleErrors)
          .then(response => response.text())
          .then(str => eval(str))
          .catch(error => {
              alert('Oops, there was an error')
              console.error(error)
          })
  }

  _handleErrors(response) {
      if (!response.ok) {
          throw Error(response.statusText);
      }
      return response;
  }
}
