import { Controller }   from 'stimulus'

export default class extends Controller {

  connect() {
    // Dropzone init
    Dropzone.autoDiscover = false

    const myDropzone = new Dropzone(this.element, {
        url: this.element.action
    })
  }
}
