import { Controller } from 'stimulus'
import CableReady     from 'cable_ready'

export default class extends Controller {

  static targets = ['message']

  // ACTION CABLE INITIALISATION
  initialize() {
    const record_type = this.data.get("recordType")
    const record_id   = this.data.get("recordId")
    const channel     = this.data.get("channel")
    this.setOwners    = this.setOwners.bind(this)
    this.received     = this.received.bind(this)
    this.params       = { channel, record_id, record_type }
  }

  connect() {
    this.setOwners()
    this.subscription = App.cable.subscriptions.create(this.params, { received: this.received })
  }

  disconnect() {
    this.subscription.unsubscribe()
  }

  received(data) {
    if (data.cableReady) CableReady.perform(data.operations)

    this.setOwners()
  }

  setOwners() {
    const currentUser = this.data.get("user")

    this.messageTargets.forEach(message => {
      if (message.dataset.author == currentUser) message.classList.add("owned")
    })
    const lastMessage = this.messageTargets[this.messageTargets.length - 1]
    if (lastMessage) {
      lastMessage.scrollIntoView()
    }
  }
}
