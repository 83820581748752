import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  refreshInterval = 15000

  connect() {
    this.active = false
  }

  toggle() {
    this.active = !this.active
    this.active ? this._activate() : this._deactivate()
  }

  _activate() {
    const button  = document.getElementById('calendar-refetch')
    button.click()
    this.interval = setInterval(() => button.click(), this.refreshInterval)
  }

  _deactivate() {
    clearInterval(this.interval)
  }
}
