import BaseNotificationController from './baseNotification_controller'


export default class extends BaseNotificationController {
  static targets = ['container', 'show', 'nomore']

  // NOTIFICATION INDEX PAGE
  // Append next page
  showNextPage() {
    const page = parseInt(this.containerTarget.dataset.currentPage)
    const total_pages = parseInt(this.containerTarget.dataset.totalPages)

    if (page <= total_pages) {
      this.containerTarget.appendChild(this._showSpinner())
      this._buildGetRequest(`notifications?page=${page}`)
        .then(response => response.json())
        .then(data => {
          this._deleteSpinner()
          data.forEach(notif => {
            this._compareDate(notif['created_at'])
            this._insertNotification(notif)
          })
        })
      // Disable btn if reached last page
      if (page === total_pages) { this._disableShowMoreBtn() }
      this.containerTarget.dataset.currentPage = page + 1
    }
  }

  // Delete a notification
  deleteNotification(event) {
    const card = event.currentTarget.parentNode.parentNode
    const notification_id = card.dataset.id
    card.style.opacity = '0.5'
    this._buildRequest('/notifications', 'DELETE', { notification_id: notification_id })
      .then(response => { if (response.ok) { card.remove() }})
  }

  // Mark all the notifications as read
  // The fetch is a remote: true in the link_to
  readAll(event) {
    event.currentTarget.classList.add('allread')

    // Mark all as read
    const displayedNotifications = this.containerTarget.childNodes
    displayedNotifications.forEach((notif) => {
      if (notif.tagName === 'DIV' && notif.classList.contains('unread')) {
        notif.classList.remove('unread')
      }
    })

    // Remove red dot from the bell and change the title
    const counter = document.getElementById('notif-nb')
    counter.dataset.unread = 0
    counter.classList.add('hidden')

    document.title = `${counter.dataset.title}`
  }

  // Private

  // Build the notification to append
  _insertNotification(notif) {
    let unread = ''
    if (!notif['read']) { unread = 'unread' }
    this.containerTarget.innerHTML += `<div class="small_cards__item my_grey ${unread}"
                                        data-date='${notif['created_at']}'
                                        data-id='${notif['id']}'
                                        data-action="mouseover->notifications--index#markAsRead">
                                          <div class="top-card-workorders-index">
                                            <div>
                                              <div>${new Date(notif['created_at']).getHours()}:${new Date(notif['created_at']).getMinutes()}</div>
                                              ${notif['informations']['bullet']}
                                              ${I18n.t(notif['informations']['translation'], notif['informations']['variables'])}
                                            </div>
                                          </div>
                                          <div class="notifications-icons">
                                            <span tooltip="Delete notification"
                                              data-action="click->notifications--index#deleteNotification">
                                              <i class="fa fa-trash"></i>
                                            </span>
                                          </div>
                                        </div>`
  }

  // nsert the date header if different from the previous one
  _compareDate(created_at) {
    // Check if the day of the last notification is different than the day of the current one
    const last_date = new Date(this.containerTarget.lastElementChild.dataset.date)
    const current_date = new Date(created_at)
    // If it is, insert a date
    if (current_date.toDateString() != last_date.toDateString()) {
      this._insertDateHeader(current_date)
    }
  }


  // Format date
  _insertDateHeader(date) {
    const locale = this.containerTarget.dataset.locale
    const formattedDate = date.toLocaleString(locale, {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    })
    // Create date header element
    const containerDiv = document.createElement('div')
    containerDiv.classList.add('notification-date-header')

    // Inserting date into container
    const dateTitle = document.createElement('h3')
    dateTitle.innerText = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
    containerDiv.appendChild(dateTitle)

    // Inserting container
    this.containerTarget.appendChild(containerDiv)
  }

  // Disable "show more" button if there's no more pages to show
  _disableShowMoreBtn() {
    this.showTarget.classList.add('disabled')
    this.nomoreTarget.classList.remove('hidden')
  }
}
