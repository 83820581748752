import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [
    'mainCheckbox',
    'dependantCheckbox'
  ]

  connect() {
    this.toggleDependant()
  }

  toggleDependant() {
    if(this.mainCheckboxTarget.checked == false) {
      this.dependantCheckboxTargets.forEach(item => {
        item.checked = false
        item.setAttribute('disabled','')
      })
    } else {
      this.dependantCheckboxTargets.forEach(item => {
        item.removeAttribute('disabled','')
      })
    }
  }

}
