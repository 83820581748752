import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'managerlist', 'usertypelist' ]
  selectManager() {
      if (this.usertypelistTarget.value == 'technician') {
          this.managerlistTarget.style.display = 'block';
      } else {
          this.managerlistTarget.style.display = 'none';
      }
  }
}
