import { Controller } from 'stimulus'

export default class extends Controller {

    toggleAdd(event) {
        event.preventDefault()
        const addButton = document.getElementById('blueprints-create-button')
        const addBox    = document.getElementById('blueprints-create-box')
        addButton.classList.toggle('d-none')
        addBox   .classList.toggle('d-none')
    }
}
