import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dateFieldWithTime", "dateFieldWithoutTime", "checkbox" ]

  connect() {
    this.toggle()
  }

  toggle() {
    if (this.checkboxTarget.checked) {
      this.dateFieldWithTimeTargets.forEach(target => {
        target.disabled = true
        target.style.display = "none"
      })
      this.dateFieldWithoutTimeTargets.forEach(target => {
        target.disabled = false
        target.style.display = "block"
      })
    } else {
      this.dateFieldWithTimeTargets.forEach(target => {
        target.disabled = false
        target.style.display = "block"
      })
      this.dateFieldWithoutTimeTargets.forEach(target => {
        target.disabled = true
        target.style.display = "none"
      })
    }
  }
}
