// <div data-controller="workorders--form--materials">
//   <h1 data-target="workorders--form--materials.output"></h1>
//   <h1 data-target="workorders--form--materials#action"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "site", "materials" ]

  connect() {
    this.fetchParams = {
      credentials: 'same-origin',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }
    this.fetchUrl = this.data.get("route")
  }

  siteChange() {
    const siteId = this.siteTarget.value
    if (siteId) {
      fetch(this.fetchUrl + `?id=${siteId}`, this.fetchParams)
      .then(response => response.text())
      .then(str => eval(str))
      .catch(error => alert('Oops, there was an error'))
    } else {
      const tasks = document.getElementById("maintenance-tasks")
      if(tasks) tasks.innerHTML = ""
    }
  }
}
