import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['photo'];

  click() {
    setTimeout(this.addPhoto.bind(this), 200)
  }

  addPhoto() {
    const index = this.photoTargets.length - 1
    const input = this.photoTargets[index]
    if (!input) return
    input.click()
  }
}
