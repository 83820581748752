import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button', 'site']

  initialize() {
    this.clientName = this.element.querySelector('#client_name')
    this.clientId = this.element.querySelector('#client_id')
    this.site = this.element.querySelector("#site_id")

    // Contact form remote link
    const contactForm = document.querySelector("#new-resp-modal")
    if (contactForm) {
      this.contactClientName = contactForm.querySelector("#contact_client_name")
      this.contactClientName.setAttribute('readonly', 'readonly')
      this.contactClientId = contactForm.querySelector("#contact_client_id")
    }

    // Site Form
    const siteForm = document.querySelector("#new-site-modal")
    if (siteForm) {
      this.siteClientName = siteForm.querySelector("#site_client_name")
      this.siteClientName.setAttribute('readonly', 'readonly')
      this.siteClientId = siteForm.querySelector("#site_client_id")
    }
  }

  connect() {
    this.setClientOnModals()
  }

  setClientOnModals() {
    const clientId = this.clientId.value
    const clientName = this.clientName.value
    this._setClientOnSiteModal(clientId, clientName)
    this._setClientOnMaterialModal(clientId, clientName)
    this._toggleAddButtons(clientId)
  }

  // PRIVATE METHODS

  _setClientOnSiteModal(clientId, clientName) {
    if (!this.siteClientName) return false

    this.siteClientName.value = clientName
    this.siteClientId.value = clientId
  }

  _setClientOnMaterialModal(clientId, clientName) {
    if (!this.materialClientName) return false

    this.materialClientId.value = clientId
    this.materialClientName.value = clientName
  }

  _toggleAddButtons(clientId) {
    this.buttonTargets.forEach((button) => {
      if (clientId) {
        if (button.innerHTML == '&nbsp;') {
          button.classList.add('d-none')
        } else {
          button.classList.remove('d-none')
        }
      } else {
        if (button.innerHTML == '&nbsp;') {
          button.classList.remove('d-none')
        } else {
          button.classList.add('d-none')
        }
      }
    })
  }

  // ALL THEESE METHODS SHOULD BE IN ANOTHER CONTROLLER
  setAddress() {
    const addressOption = this.siteTarget.selectedOptions[0]
    if (!addressOption)  return false

    const address = addressOption.dataset.address
    const address_field_workorder = document.querySelector("[name='workorder[address]']")
    if (address && address_field_workorder) {
      address_field_workorder.value = address
    } else {
      address_field_workorder.value = ''
    }
  }

  // This also
  setDefaultTech() {
    const defaultTechOption = this.siteTarget.selectedOptions[0]
    if (!defaultTechOption)  return false

    const technician = defaultTechOption.dataset.defaultTechnician
    const technician_field_workorder = document.getElementById('tech_id')
    if (!technician_field_workorder)      return false
    if (technician_field_workorder.value) return false

    if (technician && technician_field_workorder) {
      technician_field_workorder.value = technician
      technician_field_workorder.dispatchEvent(new Event('change'))
    } else {
      technician_field_workorder.value = ''
    }
  }
}
