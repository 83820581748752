import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = [  ]

    today() {
        const date = this.data.get("date");
        const dateField = document.getElementById("calendar-set-date");
        dateField.value = date
        const changeEvent = new Event('change');
        dateField.dispatchEvent(changeEvent);

        const new_state = window.location.origin + `/workorders/calendar?selected_date=${date}`;
        history.replaceState(history.state, document.title, new_state);

        const flatpickrField = document.getElementById("flatpickr-field");
        flatpickrField.value = date;
        const flatpickrButton = document.getElementById("set-flatpickr-date");
        flatpickrButton.click();
    }
}
