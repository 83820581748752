import { Controller } from "stimulus"
import { roundNumber } from "@react/quotes/form/helpers"

export default class extends Controller {
  static targets = ["range", "percentage", "quantityInput"]

  connect() {
    this.setValues()
    this.setMaxQuantity()
  }

  changeRange() {
    const percentage = this.rangeTarget.value
    this.data.set("percentage", percentage)
    this.setValues()
  }

  verifyQuantity() {
    const newQuantity = this.quantityInputTarget.value
    const max = this.quantityInputTarget.getAttribute('max')
    if (max && parseFloat(max) < newQuantity) {
      this.quantityInputTarget.value = max
    }
    if (newQuantity < 0) {
      this.quantityInputTarget.value = 0
    }
  }

  changeQuantity() {
    const quantity = parseFloat(this.quantityInputTarget.value)
    const totalQuantity = roundNumber(this.data.get("total"))
    const quantityInvoiced = roundNumber(this.data.get("quantity_invoiced"))
    const percentage = quantity ? (((quantity + quantityInvoiced) / totalQuantity) * 100) : ((quantityInvoiced / totalQuantity) * 100)
    this.data.set("percentage", percentage)
    this.rangeTarget.setAttribute('step', 0.000000001)
    this.rangeTarget.value = percentage
    this.percentageTarget.innerText = `${Math.round(percentage * 100) / 100} %`
  }

  setValues() {
    const percentage       = roundNumber(this.data.get("percentage"))
    const total            = roundNumber(this.data.get("total"))
    const quantityInvoiced = roundNumber(this.data.get("quantity_invoiced"))
    const displayQuantity  = roundNumber(((total * percentage) / 100) - quantityInvoiced)

    if (this.hasPercentageTarget) this.percentageTarget.innerText = `${percentage} %`

    if (this.hasQuantityInputTarget) {
      if (displayQuantity > 0) {
        this.quantityInputTarget.value = displayQuantity
      } else {
        this.quantityInputTarget.value = ""
      }
      this.quantityInputTarget.dispatchEvent(new Event('change'))
    }

    if (this.hasRangeTarget) {
      this.rangeTarget.value = percentage
      this.rangeTarget.setAttribute("step", 1)
    }
  }

  setMaxQuantity() {
    const total            = roundNumber(this.data.get("total"))
    const percentage       = roundNumber(this.data.get("percentage"))
    const quantityInvoiced = roundNumber(this.data.get("quantity_invoiced"))
    const quantityMax      = roundNumber(total - quantityInvoiced)
    if (this.hasQuantityInputTarget) this.quantityInputTarget.setAttribute("max", quantityMax)
  }
}
