import { Controller } from 'stimulus'

export default class extends Controller {

    open(event) {
        const openButton = event.target.closest('a')
        document.getElementById('open-today-dashboard').click()
        if (openButton.dataset.loaded === 'false') { document.getElementById('load-dashboard').click() }
    }
}
