import { Controller }  from 'stimulus'
import { bodyRequest } from "@react/helpers/fetch"

export default class extends Controller {
  initialize() {
    this.showTranslation = this.showTranslation.bind(this)
    this.showOriginal    = this.showOriginal.bind(this)
    this.click           = this.click.bind(this)
  }

  connect() {
    this.text       = { original: this.element.innerText }
    this.translated = false
    this.requested  = false
    this.element.addEventListener("click", this.click)
    this.element.classList.add("c-help")
  }

  click () {
    if (this.requested) {
      this.translated ? this.showOriginal() : this.showTranslation()
    } else {
      this.requested = true
      const url      = "/translate"
      const body     = { text: this.text.original }
      this.element.classList.remove("c-help")
      this.element.classList.add("c-wait")
      bodyRequest({ url , body })
      .then(response => {
        this.text.translated = response.text
        this.element.classList.remove("c-wait")
        this.element.classList.add("pointer")
        this.showTranslation()
      })
    }
  }

  showTranslation() {
    this.element.innerText = this.text.translated
    this.element.setAttribute("tooltip", I18n.t("button.translations_original"))
    this.translated        = true
  }

  showOriginal() {
    this.element.innerText = this.text.original
    this.element.setAttribute("tooltip", I18n.t("button.translations_translated"))
    this.translated        = false
  }
}
