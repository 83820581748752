import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['fileField', 'filename', 'fileLabel', 'preview'];

  connect() {
    const fileName = this.data.get('file')
    if (this.hasFileLabelTarget) this.fileLabelTarget.innerHTML = this._buildFileLabel(fileName)
  }

  toggleEditForm(event) {
    event.preventDefault();
    const editForm = document.getElementById(event.currentTarget.dataset.target);
    const editButton = document.getElementById(event.currentTarget.dataset.button);
    const toHideWhenEdit = document.getElementById(event.currentTarget.dataset.toHideWhenEdit);
    const toFocusWhenEdit = document.getElementById(event.currentTarget.dataset.toFocusWhenEdit);

    if (editForm)        editForm.classList.toggle('d-none');
    if (editButton)      editButton.classList.toggle('d-none');
    if (toHideWhenEdit)  toHideWhenEdit.classList.toggle('d-none');
    if (toFocusWhenEdit) toFocusWhenEdit.focus();
  }

  // Need to provide formId in the html element, ie.: "data-formId"="123"
  submitForm(event) {
    const form = document.getElementById(event.target.dataset.formId);
    form.submit();
  }

  showFileName(event) {
    const fileName = event.target.files[0].name.slice(0,100);
    const fileField = event.target.parentNode.querySelector("#fileFieldName");
    if (this.hasFilenameTarget) this.filenameTarget.innerText = fileName
    if (this.hasFileLabelTarget) this.fileLabelTarget.innerHTML = this._buildFileLabel(fileName)
    if (fileField) fileField.innerText = fileName
  }

  showImagePreview(event) {
    this._createSpinner()
    this._readfile()
    this._removeSpinner()
  }


  // private

  _createSpinner() {
    if (document.getElementById('spinner') === null) {
        const spinner = document.createElement('div')
        spinner.classList.add('spinner')
        spinner.setAttribute('id', 'spinner')
        if (this.hasPreviewTarget) {
          this.previewTarget.innerHTML = ''
          this.previewTarget.appendChild(spinner)
        }
    }
  }

  _readfile() {
    const file       = this.fileFieldTarget.files[0]
    const reader     = new FileReader();
    const controller = this
    reader.onload = (e) => {
       const image_base64 = e.target.result;
       const imgPreview   = document.createElement('img')
       imgPreview.src     = image_base64
       if (controller.hasPreviewTarget) { controller.previewTarget.prepend(imgPreview) }
    };
    reader.readAsDataURL(file);
  }

  _buildFileLabel(filename) {
    const addFileLabel = this.data.get('addFileLabel')
    const replaceFileLabel = this.data.get('replaceFileLabel')
    if (filename) {
      return `<i class="far fa-file-alt"></i> ${filename} <div>${replaceFileLabel}</div>`
    } else {
      return `<i class="fas fa-plus"></i> ${addFileLabel}`
    }
  }

  _removeSpinner() {
    document.getElementById('spinner').remove();
  }

}
