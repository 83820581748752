import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "dropdown", "description", "line", "refProduct" ]

  toggleNewLine() {
     this.formTarget.classList.toggle('hide')
  }

  toggleEdit(event) {
    let line = event.target.dataset.button
    let editForm = document.getElementById(`${line}`)
    editForm.classList.toggle('hide')
  }

  selectBlueprint(event) {
    const newLine          = event.currentTarget.closest("div.nested-fields")
    const descriptionField = newLine.querySelector('.ql-editor')
    const quantityField    = newLine.querySelector(".quantity-field");
    const unitField        = newLine.querySelector(".unit-field");
    const purchaseField    = newLine.querySelector(".purchase-price")
    const priceField       = newLine.querySelector(".price-field");
    const referenceField   = newLine.querySelector(".reference-field");
    const isOptionField    = newLine.querySelector(".is-optional")
    const hideInPdfField    = newLine.querySelector(".hide-in-pdf")

    this._setVat(newLine)

    this.dropdownTarget.classList.add('d-none')
    this.descriptionTarget.classList.remove('d-none')
    const url = this.data.get('route') + `?id=${this.dropdownTarget.value}`
    const fetch_params = {
      credentials: 'same-origin',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      }
    }

    fetch(url, fetch_params)
    .then(response => response.json())
    .then(blueprint => {
      descriptionField.innerHTML = blueprint.description
      quantityField.value        = blueprint.quantity
      purchaseField.value        = blueprint.purchase_price
      priceField.value           = blueprint.unit_price
      unitField.value            = blueprint.unit
      referenceField.value       = blueprint.ref_product_distrib
      isOptionField.checked      = blueprint.is_option
      hideInPdfField.checked     = blueprint.hide_in_pdf
      this._triggerChangeEvent()
    })
  }

  _setVat(newLine) {
    const vatField   = newLine.querySelector(".vat")
    const hidden     = document.getElementById("hidden_vat_tag")

    if (vatField) {
      vatField.value = hidden.value
      const vatRateId = newLine.querySelector(".destination-vat")
      if (vatRateId) { vatRateId.value = hidden.value }
    }
  }

  _triggerChangeEvent() {
    const change = new Event('change')
    this.lineTarget.dispatchEvent(change)
  }
}
