// <div data-controller="text-cut">
//   ...
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.toggle = this.toggle.bind(this)
  }

  connect() {
    this.element.classList.add('text-cut')
    this.element.addEventListener('click', this.toggle)
  }

  toggle() {
    this.element.classList.toggle('text-cut')
  }
}
