import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'newForm', 'appendHere']

  clone(e) {
      e.preventDefault()

      let newFieldString = this.newFormTarget.innerHTML
      newFieldString = newFieldString.replace(/property_field_blueprint_element_id/gi, Date.now())
      const newField = this._createElementFromHTML(newFieldString)

      newField.querySelectorAll('.checkbox-input').forEach((input) => {
          const newId = input.id + '_' + Date.now()
          input.id = newId
          const inputLabel = input.parentNode.querySelector('label')
          inputLabel.htmlFor = newId
      })
      newField.classList.remove("hidden");
      this.appendHereTarget.appendChild(newField)
  }

  _createElementFromHTML(htmlString) {
      const div = document.createElement('div')
      div.innerHTML = htmlString.trim()
      return div.firstChild
  }
}
