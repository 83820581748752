import { Controller } from 'stimulus'

const SIZE = 1000
export default class extends Controller {

  static targets = ["plan", "icon"]

  connect() {
    // 500ms provided for the image to laod, the onload event listener
    // was not triggering regularly
    setTimeout(this.rescale.bind(this), 500)
  }

  rescale() {
    if (this.planTarget.height == SIZE || this.planTarget.width == SIZE ) {
      // if it has not been scalled, keep the scale
      this.iconTargets.forEach(icon => icon.classList.remove("d-none"))
    } else {
      const width     = this.planTarget.width
      const height    = this.planTarget.height
      const reference = height < width ? width : height
      const scale     = reference / SIZE

      this.iconTargets.forEach(icon => {
        icon.classList.remove("d-none")
        const top  = parseFloat(icon.style.top)  * scale
        const left = parseFloat(icon.style.left) * scale
        icon.style.top  = top.toFixed(6)  + "px"
        icon.style.left = left.toFixed(6) + "px"
      })
    }

  }
}
