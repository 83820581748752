import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "origin_name",
                     "origin_address",
                     "origin_phone",
                     "origin_mobile",
                     "origin_email",
                     "origin_observation",
                     "destination_name",
                     "destination_address",
                     "destination_phone",
                     "destination_mobile",
                     "destination_email",
                     "destination_observation",   ]

  copy(e) {
   e.preventDefault()
   this.destination_nameTarget.value        = this.origin_nameTarget.value;
   this.destination_addressTarget.value     = this.origin_addressTarget.value;
   this.destination_phoneTarget.value       = this.origin_phoneTarget.value;
   this.destination_mobileTarget.value      = this.origin_mobileTarget.value;
   this.destination_emailTarget.value       = this.origin_emailTarget.value;
   this.destination_observationTarget.value = this.origin_observationTarget.value;
   let addressEvent                         = new Event('address');
   this.destination_addressTarget.dispatchEvent(addressEvent);
   this.destination_addressTarget.focus()
   this.destination_nameTarget.focus()
  }
}
