import { Controller } from 'stimulus'
import { jsRequest, pathname } from '@react/helpers/fetch'

export default class extends Controller {

  static targets = ['siteId', 'clientId']

  fetchClientUsers() {
    const path   = this.data.get("routeclients")
    const params = this._params()
    const url    = pathname({ path, params })
    jsRequest(url)
  }

  fetchSiteUsers() {
    const path   = this.data.get("routesites")
    const params = this._params()
    const url    = pathname({ path, params })
    jsRequest(url)
  }

  // Private

  _params() {
    const params = {
      workorder_id: this.data.get("workorder") || '',
      client_id:    this.clientIdTarget.value,
      site_id:      this.siteIdTarget.value,
    }
    return params
  }

}
