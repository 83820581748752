import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["create", "checkbox"];

  change() {
    this.checkboxTargets.forEach(target => {
      target.disabled = !this.createTarget.checked
      if (!this.createTarget.checked) target.checked = false
    })
  }
}
