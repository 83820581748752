import { Controller } from 'stimulus'
import CableReady     from 'cable_ready'

export default class extends Controller {

  static targets = ['message']

  // ACTION CABLE INITIALISATION
  initialize() {
    const workorder = this.data.get("workorder")
    const channel   = this.data.get("channel")
    this.params     = { channel, workorder }
  }

  connect() {
    const setOwners = this._setOwners.bind(this)

    setOwners()
    this.subscription = App.cable.subscriptions.create(this.params, {
      received (data) {
        if (data.cableReady) CableReady.perform(data.operations)

        setOwners()
      }
    })
  }

  disconnect() {
    this.subscription.unsubscribe()
  }

  _setOwners() {
    const currentUser = this.data.get("user")

    this.messageTargets.forEach(message => {
      if (message.dataset.author == currentUser) message.classList.add("owned")
    })

    // this.formTarget.reset()
    // this.formTarget.scrollIntoView()
  }
}
