// <div data-controller="workorders--form--clients">
//   <h1 data-target="workorders--form--clients.output"></h1>
//   <h1 data-target="workorders--form--clients#action"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]

  connect() {
    this.fetchParams = {
      credentials: 'same-origin',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }
    this.fetchUrl = this.data.get("route")
  }

  showClientDetails() {
    this.clientId = this.element.value
    if (this.clientId) {
      fetch(this.fetchUrl + `?id=${this.clientId}`, this.fetchParams)
      .then(response => response.text())
      .then(str => eval(str))
    }
  }
}
