import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'input', 'hidden' ]

  change(e) {
    const time    = e.target.value.split(/\D/)
    const hours   = parseInt(time[0]) || 0
    const minutes = parseInt(time[1]) || 0

    if (hours >= 24) {
      this.inputTarget.value = "24h00"
    } else {
      this.hiddenTarget.value = hours * 60 + minutes
    }
  }
}
