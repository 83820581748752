import { Controller } from 'stimulus'
import { throws } from 'assert';

export default class extends Controller {

  static targets = ['sources', 'distributor', 'searchbar', 'addBtn', 'form']

  toggleDistrib(event) {
      const distributor = event.target.nodeName == 'IMG' ? event.target.parentElement : event.target
      const sources = this.sourcesTarget.value.split(',')

      if (distributor.classList.contains('distributor-selected'))
      { this._removeDistributor(distributor, sources) }
      else
      { this._addDistributor(distributor, sources) }

      this._changePlaceHolder(sources)
  }

  toggleNewProduct() {
      this.formTarget.classList.toggle('hide')
  }

  /* Private methods */

  _changePlaceHolder(sources) {
      let distributors = sources.filter(x => x != null).filter(x => x != '').map(x => ` ${x.charAt(0).toUpperCase() + x.slice(1)}`)
      let sentence
      if (distributors.length <= 0) {
        sentence = I18n.t('products.search')
      } else {
        distributors = distributors.map(dis => dis == ' Private' ? I18n.t('products.private') : dis)
        sentence = I18n.t('products.search_in', { distributors: distributors.join(', ') })
      }

      this.searchbarTarget.setAttribute('placeholder', sentence)
  }

  _addDistributor(distributor, sources) {
      distributor.classList.add('distributor-selected')
      sources.push(distributor.dataset.name)
      this.sourcesTarget.value = sources
      this._changePlaceHolder(sources)
      // document.getElementById('search-product-bar').setAttribute('placeholder', 'Search in Facq, Cebeo, Vanmarcke or Rexel')
  }
  _removeDistributor(distributor, sources) {
      const indexToRemove = sources.indexOf(distributor.dataset.name)
      sources.splice(indexToRemove, 1)
      this.sourcesTarget.value = sources.join(',')
      distributor.classList.remove('distributor-selected')
  }
}
