import { Controller }  from "stimulus"
import { roundNumber } from "@react/quotes/form/helpers"

export default class extends Controller {
  static targets = [
    "discount",
    "storeSubtotal",
    "storeVatAmount",
    "subtotalNotDiscounted",
    "subtotalDiscounted",
    "subtotal",
    "subtotalText",
    "subtotalValue",
    "vats",
    "total"
  ]

  initialize() {
    this.skipVat = this.data.has("skipVat")
  }

  // Lines should calculate first
  connect() {
    setTimeout(() => this.calculate(), 200)
  }

  calculate() {
    this.currency = this.data.get("currency")
    this._setSubtotalNotDiscounted()
    this._setSubtotalDiscounted()
    this._setSubtotal()
    this._setVatAmount()
    this._setTotal()
  }

  _setSubtotalNotDiscounted() {
    let totals = this.storeSubtotalTargets
    // Remove destroyed lines, cocoon puts them invisible
    totals = totals.filter(e => e.closest(".nested-fields").style.display != 'none')
    totals = totals.map(target => parseFloat(target.value) || 0)
    totals = totals.reduce((a, b) => a + b, 0)
    this.subtotalNotDiscounted = roundNumber(totals)
    if (this.hasDiscountTarget) {
      this.subtotalNotDiscountedTarget.innerText = this._formatNumber(this.subtotalNotDiscounted)
    }
  }

  _setSubtotalDiscounted() {
    if (this.hasDiscountTarget) {
      this.discount           = parseFloat(this.discountTarget.value) || 0
      this.subtotalDiscounted = roundNumber(this.subtotalNotDiscounted * this.discount / 100)
      this.subtotalDiscountedTarget.innerText = this._formatNumber(this.subtotalDiscounted)
    } else {
      this.subtotalDiscounted = 0
      this.discount           = 0
    }
  }

  _setSubtotal() {
    this.subtotal = this.subtotalNotDiscounted - this.subtotalDiscounted
    if (this.hasSubtotalTextTarget) {
      this.subtotalTextTarget.innerText = this._formatNumber(this.subtotal)
    }
    if (this.hasSubtotalValueTarget) {
      this.subtotalValueTarget.value = this.subtotal
      this.subtotalValueTarget.dispatchEvent(new Event("subtotal"))
    }
  }

  _setVatAmount() {
    if(this.skipVat) return

    const vats = {}
    let lines
    lines = this.storeSubtotalTargets
    lines = lines.map(   line => line.closest(".nested-fields"))
    lines = lines.filter(line => line.style.display != 'none')

    lines.forEach(line => {
      let vat      = line.querySelector('[data-target^="financial--line.storeVat"]')
      let subtotal = line.querySelector('[data-target^="financial--line.storeSubtotal"]')

      vat          = parseFloat(vat.value)
      subtotal     = parseFloat(subtotal.value)
      if (vat && subtotal) {
        vats[vat] = (vats[vat] || 0) + subtotal * vat
      }
    })
    this.vatAmount            = 0
    if (this.hasVatsTarget) { this.vatsTarget.innerHTML = "" }
    const discountRatio       = 1 - this.discount / 100

    Object.keys(vats).sort((a, b) => b - a).forEach(vat => {
      const vatAmount = roundNumber(vats[vat] * discountRatio)
      this.vatAmount += vatAmount

      if (this.hasVatsTarget) {
        const vatHTML   = `
          <div class="d-contents">
            <div class="div1"><strong>${I18n.t("invoices.vat")} ${roundNumber(vat * 100)}%</strong></div>
            <div class="div2 text-right">${this._formatNumber(vatAmount)}</div>
          </div>
        `
        this.vatsTarget.insertAdjacentHTML('afterbegin', vatHTML);
      }
    })
  }

  _setTotal() {
    if(this.skipVat) return

    this.total = this.subtotal + this.vatAmount
    if (this.hasTotalTarget) {
      this.totalTarget.innerText = this._formatNumber(this.total)
    }
  }

  _formatNumber(number) {
    return number.toLocaleString(I18n.locale, {
      style:    'currency',
      currency: this.currency
    })
  }
}
