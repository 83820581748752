import BaseNotificationController from './baseNotification_controller'

export default class extends BaseNotificationController {
  static targets = ['bell', 'dot', 'count', 'list']

  // ACTION CABLE INITIALISATION
  initialize() {
    const dataset = document.getElementById('notification-bell').dataset
    this.params = {
      channel: 'NotificationsChannel',
      notifications: parseInt(dataset.uid)
    }
    if(this._alreadySubscribed()) return

    App.cable.subscriptions.create(this.params, {
      received: (notification) => {
        this._increaseCounter()
        this.dotTarget.classList.remove('hidden')
      }
    })
  }

  // private

  _alreadySubscribed() {
    if(!App.cable) return(false)

    const identifiers   = App.cable.subscriptions.subscriptions.map(s => s.identifier)
    const subscriptions = identifiers.map(identifier => JSON.parse(identifier))
    return subscriptions.find(s => s.channel == this.params.channel)
  }
}
