import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["list", "create", "generate", "email", "card", "maskButton"];

  connect() {
    this.create   = true
    this.generate = false
    this.email    = false
    this.mask     = true
    this.cardTargets.forEach(card => card.classList.add('d-none'))
    document.getElementById("preview-button").disabled = false
  }

  toggleCreate() {
    this.create = !this.create
    this.createTargets.forEach(checkbox => checkbox.checked = this.create)
    this.generateTargets.forEach(input => {
      input.disabled = !this.create
      if (input.disabled) input.checked = false
    })
  }

  toggleGenerate() {
    this.generate = !this.generate
    this.generateTargets.forEach(checkbox => {
      if(!checkbox.disabled) checkbox.checked = this.generate
    })
  }

  toggleEmail() {
    this.email = !this.email
    this.emailTargets.forEach(checkbox => {
      if(!checkbox.disabled) checkbox.checked = this.email
    })
  }

  toggleMask(e) {
    if (e) e.preventDefault()
    this.mask = !this.mask
    this.cardTargets.forEach(card => {
      this.mask ? card.classList.add('d-none') : card.classList.remove('d-none')
    })

    this.maskButtonTarget.innerText = this.mask
      ? I18n.t("maintenance.show_generated_invoices")
      : I18n.t("maintenance.hide_generated_invoices")
  }
}

