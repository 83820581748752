// <btn data-controller="clipboard" data-clipboard-element="#selector to copy">
//  <i class="fas fa-copy"></i>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["copy"]

  copy() {
    this.element.select()
    this.element.setSelectionRange(0, 99999)
    this._execute()
  }

  copyFromTag() {
    this.copyTarget.select()
    this.copyTarget.setSelectionRange(0, 99999)
    this._execute()
  }

  _execute() {
    document.execCommand("copy")

    if (this.data.has("message")) flashNotice(this.data.get("message"))
    const icon = this.element.querySelector(".fa-copy")
    icon.classList.remove("fa-copy")
    icon.classList.add("fa-check")
    setTimeout(() => {
      icon.classList.remove("fa-check")
      icon.classList.add("fa-copy")
    }, 2000)
  }

}
