import { Controller } from 'stimulus'

export default class extends Controller {

    toggleNewIndexForm(event) {
        document.querySelector('#new-index-form').classList.toggle('hide');
        document.querySelector('#show-new-index-button').classList.toggle('hide');
    }

    toggleEditIndexValuesForYear(event) {
        let selectedYearId = event.target.dataset.id;
        document.querySelector(`#show-${selectedYearId}`).classList.toggle('hide');
        document.querySelector(`#edit-${selectedYearId}`).classList.toggle('hide');
    }

    toggleIndexFormula(event) {
        document.getElementById('index-link').classList.toggle('title-selected');
        document.getElementById('formula-link').classList.toggle('title-selected');
        document.getElementById('index-container').classList.toggle('hide');
        document.getElementById('formula-container').classList.toggle('hide');
    }
}
