import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['parentInfo', 'parentName', 'parentId', 'clientId', 'siteId'];

  setHiddenFieldsOnModal() {
      this.parentNameTarget.innerText = this.parentInfoTarget.dataset.parentName
      this.parentIdTarget.value       = this.parentInfoTarget.dataset.parentId
      this.clientIdTarget.value       = this.parentInfoTarget.dataset.clientId
      this.siteIdTarget.value         = this.parentInfoTarget.dataset.siteId
  }
}
