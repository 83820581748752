// siteAddressSelection_controller.js

import { Controller } from 'stimulus'
import { getRequest } from '@react/helpers/fetch'

export default class extends Controller {
  static targets = ["siteId", "siteAddress", "deliveryAddressField"]

  displaySiteAddress() {
    const siteId = this.siteIdTarget.value
    const siteAddressField = this.siteAddressTarget
    const url = `/purchase_orders/site_delivery_address.json?site_id=${siteId}`
    const siteAddress = getRequest(url).then(site => {
      siteAddressField.textContent = site.address
    })
  }

  apply() {
    const addressFieldInputField = document.getElementById('address_field');
    addressFieldInputField.value = this.siteAddressTarget.textContent;
    closeActiveModal();
  }
}
