import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["preview"];

  currentIndex = 0;
  previewURLs = [];

  connect() {
    this.previewURLs = JSON.parse(this.previewTarget.dataset.pageUrls);
    this.showCurrentPreview();
  }

  next() {
    if (this.currentIndex < this.previewURLs.length - 1) {
      this.currentIndex += 1;
      this.showCurrentPreview();
    }
  }

  previous() {
    if (this.currentIndex > 0) {
      this.currentIndex -= 1;
      this.showCurrentPreview();
    }
  }

  showCurrentPreview() {
    if (this.previewURLs.length > 0) {
      this.previewTarget.src = this.previewURLs[this.currentIndex];
    }
  }
}
