import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'showbutton'];

  connect() {
    document.getElementById("preview-button").disabled = false
  }

  toggleCheckboxes(e) {
    const checked = e.target.checked
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = checked
      const changeEvent = new Event('change')
      checkbox.dispatchEvent(changeEvent)
    })
  }

  showGenerated(e) {
    e.preventDefault()
    this.element.querySelectorAll(".row.d-none").forEach(row =>
      row.classList.remove("d-none")
    )
    this.showbuttonTarget.remove()
  }

  check(e) {
    const row = e.target.closest(".row")
    row.querySelectorAll("input:not([type='checkbox'])").forEach(input => {
      input.disabled = !e.target.checked
    })

    row.querySelectorAll("select").forEach(input => {
      input.disabled = !e.target.checked
    })
  }
}
