import { Controller } from 'stimulus'
import { jsRequest }  from '@react/helpers/fetch'
import { pathname }   from '@react/helpers/fetch'

export default class extends Controller {

  fetchCategories(e) {
    const client = e.currentTarget.value
    if (!client) return

    const path   = '/categories/partners'
    const type   = this.data.get("type")
    const params = { client, type }
    const format = 'js'
    const url    = pathname({ path, params, format })
    jsRequest(url)
  }
}
