import { Controller } from 'stimulus'
import { jsRequest } from '@react/helpers/fetch'

export default class extends Controller {
  static targets = [ 'container', 'modal' ]

  show(event) {
    if(this.loading) return

    this.loading = true
    const link   = event.target.closest('[data-route]')
    const { method, route, size } = link.dataset
    const options  = { callback: this._callback.bind(this) }
    if (method) options.method = method
    this.resize(size)
    this.load()

    if(route) jsRequest(route, options)
  }

  load() {
    if (!this.hasModalTarget) return

    const spinnerHTML =
    `
      <div class="loading-spinner-container">
        <div id="loading-spinner"></div>
      </div>
    `
    this.modalTarget.innerHTML = spinnerHTML
  }

  // Private methods
  _callback() {
    if (this.hasModalTarget)
      reloadReact(this.modalTarget)
    else {
      reloadReact(this.element)
    }
    this.loading = false
  }

  resize(size) {
    if (!this.hasContainerTarget) return

    this.containerTarget.classList.remove("modal-lg")
    this.containerTarget.classList.remove("modal-xl")
    this.containerTarget.classList.remove("modal-sm")

    const sizeClass = this._getSizeClass(size)

    if (sizeClass) this.containerTarget.classList.add(sizeClass)
  }

  _getSizeClass(size) {
    switch (size) {
      case 'xl':     return "modal-xl"
      case 'small':  return "modal-sm"
      case 'medium': return ""
      default:       return "modal-lg"
    }
  }
}
