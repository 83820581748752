import { Controller } from 'stimulus'

export default class BaseNotificationController extends Controller {

  // COMMON

  // Mark a notification as read
  markAsRead(event) {
    const unreadNotification = event.currentTarget
    if (unreadNotification.classList.contains('unread')) {
      const notificationId = unreadNotification.dataset.id
      this._buildRequest('/notifications/mark_as_read', 'PATCH', { notification_id: notificationId })
        .then(response => {
          if (response.ok) {
            unreadNotification.classList.remove('unread')
            this._decreaseCounter()
          }
        })
    }
  }

  // Private

  // NOTIFICATION NAVBAR — Create new notification to append
  _insertNavNotification(notification) {
    const newNotification = document.createElement('div')

    newNotification.dataset.id = notification.id
    newNotification.classList.add('notification')

    if (!notification.read) newNotification.classList.add('unread')
    if (!notification.read) newNotification.dataset.action = 'mouseover->notifications--navbar#markAsRead'

    const localizedDate = new Date(notification['created_at']).toLocaleDateString() + ' ' + new Date(notification['created_at']).toLocaleTimeString()

    // Handle the interpolation inside I18n. Could be better handled but have to see the future usecases
    const mainChange = notification['informations']['variables']['main_change']
    if (mainChange) notification['informations']['variables']['main_change'] = I18n.t(`notifications.workorder.${mainChange}`)

    newNotification.innerHTML = `<div class="notification-time">
                                    <span>${localizedDate}</span>
                                  </div>
                                  ${notification['informations']['bullet']}
                                  ${I18n.t(notification['informations']['translation'], notification['informations']['variables'])}`
    return newNotification
  }


  // Update the counter
  // countTarget is defined in the child controller
  _increaseCounter() {
    const counter = document.getElementById('notif-nb')
    const newCount = parseInt(counter.dataset.unread) + 1
    counter.dataset.unread = newCount

    if (newCount <= 0) {
      document.title = `${counter.dataset.title}`
      counter.classList.add('d-none')
    } else {
      document.title = `(${newCount}) ${counter.dataset.title}`
      counter.classList.remove('d-none')
    }
  }

  _decreaseCounter() {
    const counter = document.getElementById('notif-nb')
    const newCount = parseInt(counter.dataset.unread) - 1
    counter.dataset.unread = newCount

    if (newCount <= 0) {
      document.title = `${counter.dataset.title}`
      counter.classList.add('d-none')
    } else {
      document.title = `(${newCount}) ${counter.dataset.title}`
      counter.classList.remove('d-none')
    }
  }

  _buildGetRequest(path) {
    return fetch(path, {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
    })
  }

  _buildRequest(path, method, body) {
    return fetch(path, {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      method: method,
      body: JSON.stringify(body)
    })
  }

  _showSpinner() {
    const spinnerContainer = document.createElement('div')
    spinnerContainer.classList.add('loading-spinner-container-sm')
    spinnerContainer.innerHTML = '<div id="loading-spinner"></div>'
    return spinnerContainer
  }

  _deleteSpinner() {
    document.querySelector('.loading-spinner-container-sm').remove()
  }
}
