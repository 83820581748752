import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {

  // Manage existing Chargebee account
  loadPortal() {
    const cbInstance = this._loadCbInstance()
    const locale = this.data.get('locale')

    cbInstance.setPortalSession(() => {
      return $.ajax({
        url: '/api/charge_bee/generate_portal_session',
        method: 'POST',
        data: {
          locale: locale
        },
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
      })
    })

    cbInstance.createChargebeePortal().open({
      loaded:              function ()       {},
      close:               function ()       {},
      paymentSourceAdd:    function (status) {},
      paymentSourceUpdate: function (status) {},
      paymentSourceRemove: function (status) {},
      subscriptionChanged: function (data)   {}
    })
  }

  _loadCbInstance() {
    if (this.data.get('env') === 'production') {
      return window.Chargebee.init({ site: 'yuman' })
    }
    else {
      return window.Chargebee.init({ site: 'yuman-test' })
    }
  }
}
