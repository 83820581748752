import { Controller } from 'stimulus'
import { jsRequest, pathname } from '@react/helpers/fetch'

export default class extends Controller {

  static targets = ["technician", "container", "external"]

  toggle() {
    const selectedOption = this.technicianTarget.selectedOptions[0]
    if (!selectedOption) return

    const isExternal = selectedOption.dataset.external
    isExternal == "true" ? this._show() : this._hide()
  }

  _show() {
    const technician = this.technicianTarget.value
    if (!technician) {
      this._hide()
    } else {
      const path   = "/workorders/form/external_technician"
      const params = { technician }
      const format = "js"
      jsRequest(pathname({ path, params, format }))
    }
  }

  _hide() {
    this.containerTarget.innerHTML = ""
  }

  select() {
    const external   = this.technicianTarget.value
    const technician = this.externalTarget.value
    const date_planned       = document.getElementById("workorder_date_planned").value
    const predicted_duration = document.getElementById("workorder_predicted_duration").value
    const container          = document.getElementById("external-technician-availability");
    container.innerHTML      = '';
    if (!technician || !date_planned || !predicted_duration || !external) return

    const path   = "/workorders/form/external_technician_availability"
    const params = { technician, external, date_planned, predicted_duration }
    const format = "js"
    const url    = pathname({ path, params, format })

    jsRequest(url)
  }
}
