import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    // Turbolinks doesnt allow this
    // window.onbeforeunload = e => 'Sure?'

    window.onbeforeunload = e => {
      const controller = document.querySelector("[data-controller='tab-close']")
      if (controller) return 'Sure?'
    }
    const content = document.querySelector(".main-container")
    const forms = content.querySelectorAll("form")

    forms.forEach(button => button.addEventListener("submit", this.submit))
  }

  submit() {
    window.onbeforeunload = null
  }
}
