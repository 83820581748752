import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = [ 'destinationField', 'originField', 'distanceField', 'timeField' ]

    recalculate() {
        const destination = this.destinationFieldTarget.value
        const origin = this.originFieldTarget.value
        if (destination && origin) {
            fetch(`/calc_travel_costs?origin=${origin}&destination=${destination}`, { method: 'POST' })
            .then(response => response.json())
            .then((data) => {
                if (data) {
                    const dist = data.distance
                    const time = data.time
                    const distanceReturn = this.distanceFieldTarget
                    const timeReturn = this.timeFieldTarget

                    if (dist) {
                        let distanceReturnValue  = ''
                        distanceReturnValue      = (dist/500).toFixed(2)
                        distanceReturn.innerHTML = `${ distanceReturnValue } km`
                    }
                    else {
                        distanceReturn.innerText = distanceReturn.dataset.distance
                    }
                    if (time != 'uncalculated') {
                        let timeReturnValue  = ''
                        timeReturnValue      = Math.round(time/30)
                        timeReturn.innerHTML = `${ timeReturnValue } min`
                    }
                    else {
                        timeReturn.innerText = timeReturn.dataset.distance
                    }
                }
            })
        }
    }
}
