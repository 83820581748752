export const lineCalculations = line => {
  // Parsing strings to floats
  const quantity       = parseFloat(line.quantity)       || 0.00
  const purchase_price = parseFloat(line.purchase_price) || 0.00
  const unit_price     = parseFloat(line.unit_price)     || 0.00
  const discount       = parseFloat(line.discount)       || 0.00

  const total           = quantity * unit_price
  const purchase        = quantity * purchase_price
  const totalDiscount   = total * discount / 100
  const totalDiscounted = total - totalDiscount

  return {
    total:            total,
    discount:         totalDiscount,
    purchase:         purchase,
    coefficient:      totalDiscounted / purchase,
    coefficientPrice: unit_price / purchase_price,
    margin:           100 * (totalDiscounted - purchase) / totalDiscounted
  }
}

export const quoteCalculations = quote => {
  const calculations = {
    total:            0,
    subTotal:         0,
    subTotalDiscount: 0,
    totalDiscount:    0,
    purchase:         0,
    coefficient:      0,
    margin:           0,
    vats:             {}
  }

  quote.qlines.forEach(line => {
    if (line.option || line.destroyed) return

    const lineCalculation = lineCalculations(line)

    const lineSubTotal = lineCalculation.total
    if (lineSubTotal == 0) return
    calculations.subTotal += roundNumber(lineSubTotal)

    const linePurchase = lineCalculation.purchase || 0
    calculations.purchase += roundNumber(linePurchase)

    const lineDiscount = lineCalculation.discount
    calculations.subTotalDiscount += roundNumber(lineDiscount)

    const lineVatKey = line.vat_rate.percentage
    if (!lineVatKey) return

    const lineVat = (calculations.vats[lineVatKey] || 0) + lineSubTotal - lineDiscount
    calculations.vats[lineVatKey] = roundNumber(lineVat)
  })

  calculations.subTotal = calculations.subTotal - calculations.subTotalDiscount
  calculations.totalDiscount = calculations.subTotal * quote.discount / 100
  calculations.total = calculations.subTotal - calculations.totalDiscount

  if (calculations.purchase > 0) {
    calculations.coefficient = calculations.subTotal / calculations.purchase
    calculations.margin      = 100 * (1 - calculations.purchase / calculations.subTotal)
  }

  Object.entries(calculations.vats).forEach(vat => {
    const percentage = vat[0]
    const subTotal   = vat[1]
    const vatAmount  = roundNumber(percentage * (subTotal * (100 - quote.discount)/100) / 100)
    calculations.vats[percentage] = vatAmount
    calculations.total            += vatAmount
  })

  return calculations
}

export const roundNumber  = number => {
  const float = parseFloat(number, 10)
  if(!float) return 0
  return parseFloat(float.toFixed(2))
}

export const nameFor = attribute => `quote[${attribute}]`
export const lineNameFor = (attribute, index) => `quote[qlines_attributes][${index}][${attribute}]`

export const formatNumber = number => {
  if (!number || !isFinite(number)) return "-"

  return (number).toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}

export const setSubTotals = quote => {
  if (!quote.qlines.find(line => line.type == "subtotal")) return

  let currentGroup = []
  quote.qlines.forEach(line => {
    if (line.destroyed) return

    if (line.type == "subtotal") {
      const totals = currentGroup.map(line => {
        if(line.option) return 0

        const calculations = lineCalculations(line)
        return calculations.total - calculations.discount
      })
      const total      = totals.length == 0 ? 0 : totals.reduce((a, b) => a + b)
      const quantity   = line.quantity || 1

      line.quantity   = quantity
      line.unit_price = roundNumber(total / quantity)
      line.option     = true
      currentGroup    = []
    } else {
      currentGroup.push(line)
    }
  })
}
