import { Controller } from 'stimulus'

import { getRequest, pathname } from '@react/helpers/fetch'
export default class extends Controller {

  static targets = ['input', 'container', 'quantity', 'serial', 'serialName']

  fetch() {
    this.containerTarget.innerHTML = ""
    const product = this.inputTarget.value
    if (!product) return

    const technician = this.data.get("technician") || document.getElementById("tech_id")?.value || ""
    const params     = { product, technician }
    const path       = '/workorders/products/fetch_stocks'
    const format     = 'json'
    const url        = pathname({ path, params, format })

    getRequest(url).then(this.handleResponse.bind(this))
  }

  handleResponse(response) {
    this.containerTarget.innerHTML = response.html
    if (this.hasSerialTarget) {
      this.quantityTarget.value    = 1
      this.quantityTarget.readOnly = true
      if (this.hasSerialNameTarget) {
        this.serialTarget.name     = this.serialNameTarget.name
      }
    } else {
      this.quantityTarget.readOnly = false
      this.quantityTarget.value    = ''
      this.quantityTarget.focus()
    }
  }
}
