import { Controller }   from "stimulus"
import { directUpload } from '@helpers/javascript/cable/directUpload'
import Compressor       from 'compressorjs';


export default class extends Controller {
  static targets = [ 'input', 'fileField', 'deleteField', 'preview' ]

  uploadPhoto() {
    if (!( window.File && window.FileReader && window.FileList && window.Blob ) ) {
      alert('The File API is not supported in this browser')
    } else {
      this._createSpinner()
      if (this.hasDeleteFieldTarget) this.deleteFieldTarget.value = false
      this._readfiles(this.fileFieldTarget.files)
    }
  }

  deletePhoto() {
    // set preview to empty placeholder img
    this.previewTarget.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII="
    this.previewTarget.parentNode.classList.add('placeholder');
    this.inputTarget.disabled = true
    // set 'deleteField' value to true
    if (this.hasDeleteFieldTarget) this.deleteFieldTarget.value = true
    // delete selected photo if it exists
    const hiddenBase64Input = this.previewTarget.children[0]
    if (hiddenBase64Input) hiddenBase64Input.remove();
  }


  // private

  _createSpinner() {
    if (document.getElementById('photo-spinner') === null) {
      const spin_doc = document.createElement('div')
      spin_doc.classList.add('spinner')
      spin_doc.setAttribute('id', 'photo-spinner')
      this.previewTarget.prepend(spin_doc)
    }
  }

  _readfiles(files) {
    this.element.closest("form").querySelectorAll('[type=submit]').forEach(submit => {
      submit.disabled = true
    })

    for (var i = 0; i < files.length; i++) {
      this._processfile(files[i])
    }
    this.fileFieldTarget.value = ''
  }

  _processfile(file) {
    if( !( /image/i ).test( file.type ) )
    {
      alert( 'File '+ file.name +' is not an image.' )
      document.getElementById('photo-spinner').outerHTML = ''
      return false
    } else {
      const controller = this
      new Compressor(file, {
        quality:    0.6,
        maxHeight: 1024,
        maxWidth:  1024,
        success(result) {
          controller._appendCompressedImgToInputAndPreview(result)
          controller._removeSpinner()
        },
        error(err) {
          console.error(err.message)
          controller._removeSpinner()
        }
      })
    }
  }

  _appendCompressedImgToInputAndPreview(file) {
    directUpload(file)
    .then(avatar => {
      this.inputTarget.value    = avatar
      this.inputTarget.disabled = false
      this.element.closest("form").querySelectorAll('[type=submit]').forEach(submit => {
        submit.disabled = false
      })
    })

    const reader  = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      this.previewTarget.src = reader.result
    }
  }

  _removeSpinner() {
    document.getElementById('photo-spinner').remove();
  }
}
