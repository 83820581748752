// <div data-controller="components--multi-slider">
//   <h1 data-target="components--multi-slider.lower"></h1>
// </div>
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["lower", "upper", "output"]

  connect() {
    this.min  = parseInt(this.lowerTarget.min)
    this.max  = parseInt(this.lowerTarget.max)
    this.step = parseInt(this.lowerTarget.step) || 1
    this._setValues()
    this._displayRange()
  }

  onChangeUpper() {
    this._setValues()
    if(this.upperValue <= this.lowerValue) {
      if(this.upperValue == this.min) {
        this.upperValue        = this.min + this.step
        this.upperTarget.value = this.upperValue
      }
      this.lowerValue        = this.upperValue - this.step
      this.lowerTarget.value = this.lowerValue
    }
    this._displayRange()
  }

  onChangeLower() {
    this._setValues()
    if(this.upperValue <= this.lowerValue) {
      if(this.lowerValue == this.max) {
        this.lowerValue        = this.max - this.step
        this.lowerTarget.value = this.lowerValue
      }
      this.upperValue        = this.lowerValue + this.step
      this.upperTarget.value = this.upperValue
    }
    this._displayRange()
  }

  setRange() {
    this._setValues()
    const date    = moment().startOf('day')
    const minTime = date.hour(this.lowerValue).format("HH:mm:ss")
    let   maxTime = date.hour(this.upperValue).format("kk:mm:ss")

    this.element.dataset.minTime = minTime
    this.element.dataset.maxTime = maxTime
    this.element.dispatchEvent(new Event("setRange"))
  }

  _setValues() {
    this.lowerValue = parseInt(this.lowerTarget.value)
    this.upperValue = parseInt(this.upperTarget.value)
  }

  _displayRange() {
    this.outputTarget.innerText = `${this.lowerValue}h - ${this.upperValue}h`
  }
}
