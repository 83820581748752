import { Controller } from 'stimulus'
import flatpickr      from 'flatpickr'
import monthSelect    from 'flatpickr/dist/plugins/monthSelect';

import { Dutch }      from 'flatpickr/dist/l10n/nl.js'
import { French }     from 'flatpickr/dist/l10n/fr.js'
import { German }     from 'flatpickr/dist/l10n/de.js'
import { Italian }    from 'flatpickr/dist/l10n/it.js'
import { Polish }     from 'flatpickr/dist/l10n/pl.js'
import { Spanish }    from 'flatpickr/dist/l10n/es.js'

export default class extends Controller {

    static targets = [ 'dateField' ]

    connect() {
      this.dateFieldTargets.forEach((element) => {

        flatpickr.localize(flatpickr.l10ns[I18n.locale])

        // Plugins:
        const plugins = [];
        const useMonthSelect = element.dataset.flatpickrUseMonthSelect === 'true';
        if (useMonthSelect) {
          plugins.push(new monthSelect({
            shorthand:  true,
            dateFormat: this.data.get('dateFormat'),
            altFormat:  this.data.get('altFormat') || null
          }));
        }

        this.flatpickr = flatpickr(element, {
          dateFormat:            this.data.get('dateFormat'),
          defaultDate:           this.data.get('defaultDate') || null,
          altInput:              this.data.has('altInput'),
          altFormat:             this.data.get('altFormat') || null,
          minDate:               this.data.get('minDate') || null,
          maxDate:               this.data.get('maxDate') || null,
          locale:                { rangeSeparator: I18n.t("date.range_separator") },
          time_24hr:             true,
          inline:                this.data.get('inline') === 'true' || false,
          enableTime:            this.data.has('enableTime'),
          plugins:               plugins,
          mode:                  this.data.get('mode'),
          noCalendar:            this.data.get('noCalendar'),
          defaultHour:           this.data.get('defaultHour') || null,
          defaultMinute:         this.data.get('defaultMinute') || null,
          placeholder:           this.data.get('placeholder'),
          allowInput:            this.data.get('allowInput') === 'true' || false,
          shorthandCurrentMonth: this.data.get('shorthandCurrentMonth') === 'true' || false,
          minuteIncrement:       15,

          onClose: function(selectedDates, dateStr, instance) {
            const data = this.element.dataset

            if (data.flatpickrSubmit === 'true') this.element.closest('form').submit()

            if (data.triggerChange) this.element.dispatchEvent(new Event('change'))

            if (data.flatpickrNoAction !== undefined) {
              const form_start_date = this.element.closest('form').querySelector('#absence_start_date')
              const form_end_date   = this.element.closest('form').querySelector('#absence_end_date')
              if (data.flatpickrNoAction === 'absence-start') {
                if (!form_end_date.value) {
                  form_end_date.value = form_start_date.value
                }
                const setMinDateEvent = new Event('setMinDateEvent')
                form_end_date.dispatchEvent(setMinDateEvent)
              } else if (data.flatpickrNoAction === 'absence-end') {
                if (!form_start_date) {
                  form_start_date.value = form_end_date.value
                }
                const setMaxDateEvent = new Event('setMaxDateEvent')
                form_start_date.dispatchEvent(setMaxDateEvent)
              }
            }
          },

          onChange: function(selectedDates, dateStr, instance) {
            // I pass the value to an input on the calendar wich has a change event to set the calendar date...... blabal
            const data = this.element.parentElement.dataset
            if (data.flatpickrNoAction === 'calendar-set-date') {
              // Change the calendar input to set the date
              const date = instance.formatDate(selectedDates[0], 'Y-m-d')
              const dateField = document.getElementById('calendar-set-date')
              if (dateField) {
                dateField.value = date
                const changeEvent = new Event('change')
                dateField.dispatchEvent(changeEvent)
              }
              const selected_date = instance.formatDate(selectedDates[0], 'Y-m-d')
              const new_state = window.location.origin + `/workorders/calendar?selected_date=${selected_date}`
              history.replaceState(history.state, document.title, new_state)
            }
          },
        })
      })
    }

    setDate() {
      this.flatpickr.setDate(this.dateFieldTarget.value, false, 'Y-m-d')
    }

    setMinDate() {
      const dateStr = this.element.closest('form').querySelector('#absence_start_date:not([disabled])').value
      this.flatpickr.set('minDate', dateStr)
    }

    setMaxDate() {
      const dateStr = this.element.closest('form').querySelector('#absence_end_date:not([disabled])').value
      this.flatpickr.set('maxDate', dateStr)
    }
}

