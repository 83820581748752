import { Controller } from 'stimulus'

export default class extends Controller {

    select_manager(event){
        this._setupInstanceVariables()
        if (event.target.value) {
            this._handleSelectedManagerNotNil()
        } else {
            this._handleSelectedManagerIsNil()
        }
    }

    select_technician(event){
        this._setupInstanceVariables()
        if (event.target.value) {
            this._handleSelectedTechnicianNotNil()
        } else {
            this._handleSelectedTechnicianIsNil()
        }
        this._handleChangeOfTechnician()
    }

    checkMulti() {
        this._setupInstanceVariables()
        if (this.multiTechCheckbox.checked) {
            this.multi_techs.classList.remove('d-none')
        } else if (!this.multiTechCheckbox.checked) {
            this.multi_techs.classList.add('d-none')
            document.getElementById('multiple-technicians').querySelectorAll('.checkbox').forEach( checkBox => {
                checkBox.querySelector('input[type=\'checkbox\']').checked = false
            })
        }
    }

    check_extra_technicians(event) {
        this._setupInstanceVariables()
        if (this.multi_techs) {
            if (event.target.checked) {
                this.multi_techs.classList.remove('d-none')
            } else {
                this.multi_techs.classList.add('d-none')
                document.querySelectorAll('.checkbox').forEach( checkBox => {
                    checkBox.querySelector('input[type=\'checkbox\']').checked = false
                })
            }
        }
    }

    // Private Methods

    _setupInstanceVariables() {
        this.selectedTech      = document.getElementById('tech_id') || document.getElementById('workorder_technician_id')
        this.manager_field     = document.getElementById('workorder_manager_id')
        this.multiTechCheckbox = document.getElementById('multiTechCheckbox')
        this.multi_techs       = document.getElementById('multiple-technicians')
    }

    _handleChangeOfTechnician() {
        const nextTechId = this.selectedTech.value
        if (this.multi_techs && nextTechId !== '') {
            document.querySelectorAll('.checkbox.d-none').forEach( checkBox => {
                checkBox.classList.remove('d-none')
                checkBox.querySelector('input[type=\'checkbox\']').checked = false
            })
            document.getElementById(`tech_field_${nextTechId}`).classList.add('d-none')
            this._showScheduled()
        } else if (this.multi_techs && nextTechId === '' && !this.multi_techs.classList.contains('d-none')) {
            this.multi_techs.querySelectorAll('input[type=\'checkbox\']').forEach(checkbox => checkbox.checked = false)
            this._showOpen()
        } else if (!this.multi_techs && nextTechId !== '') {
            this._showScheduled()
        } else if (!this.multi_techs && nextTechId === '') {
            this._showOpen()
        }
    }

    _handleSelectedTechnicianIsNil() {
        this._showOpen()
        if (this.multi_techs) {
            this.multiTechCheckbox.nextElementSibling.classList.add('d-none')
            this.multi_techs.querySelectorAll('input[type=\'checkbox\']').forEach(checkbox => checkbox.checked = false)
            this.multiTechCheckbox.checked = false
            if (!this.multi_techs.classList.contains('d-none')) {
                this.multi_techs.classList.add('d-none')
            }
        }
    }

    _handleSelectedTechnicianNotNil() {
        this._showScheduled()
        if (this.multi_techs) {
            this.multiTechCheckbox.nextElementSibling.classList.remove('d-none')
        }
    }

    _showScheduled() {
        document.getElementById('status-open').classList.add('d-none')
        document.getElementById('status-scheduled').classList.remove('d-none')
    }

    _showOpen() {
        document.getElementById('status-scheduled').classList.add('d-none')
        document.getElementById('status-open').classList.remove('d-none')
    }

    _handleSelectedManagerNotNil() {
        if (this.selectedTech.value === '') {
            this._showOpen()
        } else if (this.selectedTech.value !== '') {
            this._showScheduled()
        }
    }

    _hideAllBadges() {
        document.querySelectorAll('.status-field').forEach((badgeField) => {
            badgeField.classList.add('d-none')
        })
    }

    _handleSelectedManagerIsNil() {
        this._hideAllBadges()
        this.selectedTech.value = ''
        if (this.multi_techs) {
            if (!this.multi_techs.classList.contains('d-none')) {
                this.multi_techs.classList.add('d-none')
                this.multi_techs.querySelectorAll('input[type=\'checkbox\']').forEach(checkbox => checkbox.checked = false)
            }
            if (this.multiTechCheckbox.checked) {
                this.multiTechCheckbox.checked = false
            }
            if (!this.multi_techs.classList.contains('d-none')) {
                this.multi_techs.classList.add('d-none')
            }
            if (!this.multiTechCheckbox.nextElementSibling.classList.contains('d-none')) {
                this.multiTechCheckbox.nextElementSibling.classList.add('d-none')
            }
        }
    }
}
