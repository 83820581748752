import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  updateStatus() {
    if ( event.target.value == "Rejected" || event.target.value == "Accepted" ) {
      alert(I18n.t("quotes.edit.alert_status_change"))
    }
  }
}
