// This controller is mainly used to have a bootstrap modal inside another bootstrap modal
// And it's very dirty

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  connect() {
    this.modal       = this.element
    this.parentModal = this.element.parentNode.closest(".modal")
    if (this.parentModal)
      this.parentModal.addEventListener("click", () => { this._removeBackdrops() })
  }

  hideModal() {
    document.body.classList.remove('modal-open')
    this.modal.classList.remove("show")
    this.modal.style.display = "none"
    this._removeBackdrops()
  }

  _removeBackdrops() {
    document.querySelectorAll(".modal-backdrop.fade.show").forEach(backDrop => {
      backDrop.remove()
    })
  }
}
