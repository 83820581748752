import { Controller } from 'stimulus'
import { jsRequest, pathname } from '@react/helpers/fetch'

export default class extends Controller {

  static targets = ['siteId']

  fetchSiteContacts() {
    if (!this.siteIdTarget.value) return
    const path   = this.data.get("routesites")
    const params = this._params()
    const url    = pathname({ path, params })
    jsRequest(url)
  }

  // Private

  _params() {
    const params = {
      workorder: this.data.get("workorder") || '',
      site:      this.siteIdTarget.value,
    }
    return params
  }

}
