import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [
    'setpassword',
    'password',
    'submitInvitationButton',
    'submitDirectlyButton',
    'submitButton',
    'check1',
    'check2',
    'square1',
    'square2'
  ]

  viaInvitation() {
    this.setpasswordTarget.classList.add('hide')
    this.check1Target.classList.remove('hide')
    this.check2Target.classList.add('hide')
    this.square1Target.classList.add('hide')
    this.square2Target.classList.remove('hide')
    this.passwordTargets.forEach((t) => { t.removeAttribute('required') })
    this.submitButtonTarget.removeAttribute('disabled')
    this.submitButtonTarget.value = this.submitInvitationButtonTarget.innerText
  }

  directly() {
    this.setpasswordTarget.classList.remove('hide')
    this.check1Target.classList.add('hide')
    this.check2Target.classList.remove('hide')
    this.square1Target.classList.remove('hide')
    this.square2Target.classList.add('hide')
    this.passwordTargets.forEach((t) => { t.setAttribute('required', 'required') })
    this.submitButtonTarget.removeAttribute('disabled')
    this.submitButtonTarget.value = this.submitDirectlyButtonTarget.innerText
  }
}
