export const roundMoney = amount => {
  let money
  if (typeof(amount) == "string") {
    money = parseFloat(amount.replace(/,/, '.'), 10)
  } else {
    money = parseFloat(amount, 10)
  }

  if (isNaN(money)) return 0

  if (money < 0) {
    money = Math.floor(money * 100) / 100
  } else {
    money = Math.ceil(money * 100) / 100
  }
  return money
}

function camelToSnake(string) {
  return string.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
}

export const convertKeysToSnake = obj =>  {
  if (typeof obj !== 'object' || obj === null) {
      return obj;
  }

  if (Array.isArray(obj)) {
      return obj.map(value => convertKeysToSnake(value));
  }

  return Object.keys(obj).reduce((acc, key) => {
      acc[camelToSnake(key)] = convertKeysToSnake(obj[key]);
      return acc;
  }, {});
}

export const skipWeekends = date => {
  if (date.day() == 0 ) {
    date.add(1, 'days')
  }
  if (date.day() == 6 ) {
    date.add(2, 'days')
  }
  return date.toISOString()
}

export const parsePredicted = dates => {
  const hours   = dates[0].getHours()
  const minutes = dates[0].getMinutes()
  return hours * 60 + minutes
}

export  const getDate = duration => {
  const date     = new Date()
  const hours    = duration / 60
  const minutes  = duration % 60
  date.setHours(hours)
  date.setMinutes(minutes)

  return date
}

export const msToTime = duration => {
  let minutes = Math.floor((duration / (1000 * 60)) % 60)
  let hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

  hours = (hours < 10) ? "0" + hours : hours
  minutes = (minutes < 10) ? "0" + minutes : minutes

  return hours + ":" + minutes
}
