import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'selector',
    'input',
    'container',
    'badgeContainer',
    'filterCheckbox',
    'requiredContainer',
    'requiredCheckbox'
  ]

  connect() { this.change() }

  change() {
    const value    = this.selectorTarget.value
    const multi    = value === this.data.get("multi")
    const checkBox = value === this.data.get("checkbox")
    const boolean  = value === this.data.get("boolean")

    // Display or hide options input
    if (multi || checkBox) {
      this.showContainer()
      this.inputTarget.disabled = false
    } else {
      this.hideContainer()
      this.inputTarget.disabled = true
    }

    // Display or hide checkbox to appear as badge
    if (multi) {
      this.badgeContainerTarget.classList.remove("d-none")
    } else {
      this.badgeContainerTarget.classList.add("d-none")
      this.filterCheckboxTargets.forEach(target => {
        target.checked = false
      })
    }

    // Display or hide checkbox to be required
    if(checkBox) {
      this.requiredContainerTarget.classList.add("d-none")
      this.requiredCheckboxTarget.checked = false
    } else {
      this.requiredContainerTarget.classList.remove("d-none")
    }
  }

  showContainer() {
    this.containerTarget.classList.add("d-contents")
    this.containerTarget.classList.remove("d-none")
  }

  hideContainer() {
    this.containerTarget.classList.remove("d-contents")
    this.containerTarget.classList.add("d-none")
  }
}
