import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "hideable", "resettable", "typeSelect" ]

  connect() {
    this.showOnValue = this.data.get('showOnValue')
    this.data.get('showOnInit') == "true" ? this._show() : this._hide()
  }

  toggle() {
    this.typeSelectTarget.value == this.showOnValue ? this._show() : this._hide()
  }

  _show() {
    this.shown = true
    this.hideableTargets.forEach(item => item.classList.remove('d-none'))
    this.resettableTargets.forEach(item => item.disabled = false)
  }

  _hide() {
    this.shown = false
    this.hideableTargets.forEach(item => item.classList.add('d-none'))
    this.resettableTargets.forEach(item => item.disabled = true)
  }
}
