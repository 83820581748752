import { Controller } from 'stimulus';
import Sortable from 'sortablejs';

export default class extends Controller {

  static targets = [ 'input' ]

  initialize() {
    this.onEnd = this.onEnd.bind(this)
    this.onStart = this.onStart.bind(this)
  }

  connect() {
    new Sortable(this.element, {
      handle:    '.sortable-handle',
      animation: 150,
      dragClass: "sorting--current",
      onEnd:     this.onEnd,
      onStart:   this.onStart
    })
  }

  onEnd(e) {
    this.element.querySelectorAll(".nested-fields").forEach(row =>
      row.classList.remove("sorting")
    )
    this.inputTargets.map((orderInput, index) => orderInput.value = index + 1)
    this._remountComponents(e.item)
  }

  _remountComponents(item) {
    const quillComponent = item.querySelector("[data-react-class='quillInput']")
    if (quillComponent) {
      const props = JSON.parse(quillComponent.dataset.reactProps)
      quillComponent.dataset.reactProps = JSON.stringify({...props, text: this.text})
      ReactRailsUJS.unmountComponents(item)
      ReactRailsUJS.mountComponents(item)
    }
  }

  onStart(e) {
    this._prepareComponents(e.item)
    this.element.querySelectorAll(".nested-fields").forEach(row => {
      row.classList.add("sorting")
    })
  }

  _prepareComponents(item) {
    const quillComponent = item.querySelector(".ql-editor")
    if (quillComponent) {
      this.text = quillComponent.innerHTML
    } else {
      this.text = ""
    }
  }
}
