import { Controller }  from 'stimulus'
import { formRequest, pathname } from '@react/helpers/fetch'

const INTERVAL = 5000

export default class extends Controller {

  initialize() {
    this.eventListener = this.eventListener.bind(this)
    this.autosave      = this.autosave.bind(this)
  }

  connect() {
    const path = new URL(this.element.action).pathname
    this.url   = pathname({ path, format: 'json' })
    this.element.addEventListener("click", this.eventListener)
  }

  disconnect() {
    if (this.timeout) clearTimeout(this.timeout)
  }

  autosave() {
    if(this._invalid()) return

    const form   = new FormData(this.element)
    const url    = this.url
    const method = 'PATCH'
    formRequest({ url, method, form })
    .then(_response => {
      this.element.querySelectorAll(".nested-fields").forEach(field => {
        if (field.style.display == "none") field.remove()
      })
    })
  }

  eventListener() {
    if (this.timeout) clearTimeout(this.timeout)

    this.timeout = setTimeout(this.autosave, INTERVAL)
  }

  _invalid() {
    const inputs  = Array.from(this.element.querySelectorAll("input, select"))
    const invalid = inputs.find(input => !input.checkValidity())
    return invalid ? true : false
  }
}
