import { Controller } from 'stimulus'
import CableReady     from 'cable_ready'

export default class extends Controller {

  // ACTION CABLE INITIALISATION
  initialize() {
    const channel = "WorkordersChannel"
    const account = this.data.get("account")
    this.params   = { channel, account }
  }

  connect() {
    if(this._alreadySubscribed()) return

    this.subscription = App.cable.subscriptions.create(this.params, {
      received (data) {
        if (data.cableReady) CableReady.perform(data.operations)
      }
    })
  }

  disconnect() {
    this.subscription.unsubscribe()
  }

  _alreadySubscribed() {
    const identifiers   = App.cable.subscriptions.subscriptions.map(s => s.identifier)
    const subscriptions = identifiers.map(identifier => JSON.parse(identifier))
    return subscriptions.find(s => s.channel == this.params.channel)
  }
}
