import { Controller } from 'stimulus'
import Awesomplete    from 'awesomplete'
import { getRequest, jsRequest, pathname } from '@react/helpers/fetch'

export default class extends Controller {
  static targets = [ 'search', 'inputId', 'refresh' ]

  connect() {
    this.awesompleteQuery
    this.autocompleteRefreshTime = 150

    this.awesomplete = new Awesomplete(this.searchTarget, {
      list: [],
      replace: (text) => {
        this.searchTarget.value = text.label
        this.searchTarget.closest('.form-group').classList.remove('has-danger')
        if (this.hasInputIdTarget) {
          this.inputIdTarget.value = text.value
          const changeEvent = new Event('change')
          this.inputIdTarget.dispatchEvent(changeEvent)
        }
        this.awesomplete.close()
        this._selectItem(text.value)
      }
    })
    this.searchTarget.setAttribute('autocomplete','off')
  }

  keyup(e) {
    let input = String.fromCharCode(e.keyCode);
    if (e.keyCode === 229) { input = 'android' }
    if (e.key === 'Enter') { e.preventDefault() }
    if (/[a-zA-Z0-9-_\b ]/.test(input)) {
      this.awesompleteQuery = setTimeout(() => {
        const path   = this.data.get('url')
        const params = { q: this.searchTarget.value }
        const url    = pathname({ path, params })
        getRequest(url)
        .then(data => {
          this.awesomplete.list = data.map(object => (
            {
              label: object.name,
              value: object.id
            }
          ))
        })
      }, this.autocompleteRefreshTime)
    }
  }

  keydown(e) {
    let input = String.fromCharCode(e.keyCode);
    if (/[a-zA-Z0-9-_ \b]/.test(input)) {
      if (this.inputIdTarget.value) {
        this.inputIdTarget.value = ''
        const changeEvent = new Event('change')
        this.inputIdTarget.dispatchEvent(changeEvent)
      }
      clearTimeout(this.awesompleteQuery)
    }
  }

  blur() {
    if (this.inputIdTarget.value)
      this.searchTarget.closest('.form-group').classList.remove('has-danger')
    else
      this.searchTarget.closest('.form-group').classList.add('has-danger')
  }

  reset(event) {
    event.preventDefault()
    this.searchTarget.value  = ''
    this.inputIdTarget.value = ''
    this.searchTarget.closest('.form-group').classList.remove('has-danger')
    this.refreshTargets.forEach(target => {
      if (target.value) {
        target.value = ''
        target.querySelectorAll('option').forEach(option => {
          if (option.value) { option.remove() }
        })
      }
    })
  }

  // Private

  _selectItem(id) {
    if (this.data.has('selecturl') && this.data.get('selecturl') !== '') {
      const base_url = this.data.get('selecturl');
      const full_url = (base_url.includes('?')) ? (base_url + `&id=${id}`) : (base_url + `?id=${id}`);
      jsRequest(full_url)
    }
  }
}
