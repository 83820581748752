import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'input', 'toggle', 'react', 'reset' ]

  clear(event) {
    event.preventDefault()
    this.inputTargets.forEach( target => target.value = "")
    this.toggleTargets.forEach(target => target.value = "")
    this.toggleTargets.forEach(target => target.classList.add("d-none"))
    this.reactTargets.forEach(component => {
      component.querySelectorAll("input").forEach(input => input.value = "")
    })
    this.resetTargets.forEach(component => {
      const reset = component.querySelector(".combobox__multiple__reset, .autocomplete__reset")
      if (reset) reset.click()
    })
  }

  toggle(event) {
    if (event.target.value === "") {
      this.toggleTargets.forEach(target => target.classList.add("d-none"))
    } else {
      this.toggleTargets.forEach(target => target.classList.remove("d-none"))
    }
  }
}
