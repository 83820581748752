import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['spinner', 'iframe']

  initialize() {
    this.onIframeLoad = this.onIframeLoad.bind(this)
  }

  connect() {
    this.element.innerHTML = ""
    this.buildSpinner()
    this.buildIframe()
  }

  buildSpinner() {
    const spinner  = document.createElement("div")
    spinner.height = this.data.get("height")
    spinner.width  = this.data.get("width")
    spinner.dataset.target = "iframe.spinner"
    spinner.classList.add("loading-spinner-container")

    const spinnerElement = document.createElement("div")
    spinnerElement.id    = "loading-spinner"
    spinner.appendChild(spinnerElement);

    this.element.appendChild(spinner)
  }

  buildIframe() {
    const iframe          = document.createElement("iframe")
    iframe.src            = this.data.get("route")
    iframe.height         = this.data.get("height")
    iframe.width          = this.data.get("width")
    iframe.frameborder    = 0
    iframe.dataset.target = "iframe.iframe"
    iframe.style.display  = 'none'
    iframe.addEventListener("load", this.onIframeLoad)
    this.element.appendChild(iframe)
  }

  onIframeLoad() {
    this.spinnerTarget.remove()
    this.iframeTarget.style.display = ""
  }
}