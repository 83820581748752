import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'bigCardContainer' ]

  connect() {
    setTimeout(this._scrollCard, 100)
  }

  show(e) {
    this._selectCard(e)
    this._showSpinner()
  }

  // Private methods

  _selectCard(e) {
    const smallCard = e.target.closest(".small_cards__item")
    if (!smallCard) return

    unselectCards()
    smallCard.classList.add("selected-card")
  }

  _showSpinner() {
      const bigCardContainer = this.bigCardContainerTarget
      const spinnerHTML =
    ` <div class="loading-spinner-container">
        <div id="loading-spinner"></div>
      </div>
    `
      bigCardContainer.innerHTML = spinnerHTML
  }

  _scrollCard() {
    const selectedCard = document.querySelector(".selected-card")
    if(!selectedCard) return

    const options = {
      behavior: "smooth",
      block:    "start",
      inline:   "nearest"
    }

    selectedCard.scrollIntoView(options)
  }
}
