import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'table']

  initialize() {
    this.showTable    = this.data.get("showTable")    == "true"
    this.showCheckbox = this.data.get("showCheckbox") == "true"
  }

  connect() {
    this._dispatch()
  }

  toggleCheckbox() {
    this.showCheckbox = !this.showCheckbox
    if (!this.showCheckbox) this.showTable = true
    this._dispatch()
  }

  toggleTable() {
    this.showTable = !this.showTable
    this._dispatch()
  }

  _dispatch() {
    const input = this.checkboxTarget.querySelector("input")

    this.showTable
      ? this.tableTarget.classList.remove("d-none")
      : this.tableTarget.classList.add("d-none")

    if (this.showCheckbox) {
      this.checkboxTarget.classList.remove("d-none")
      input.disabled = false
    } else {
      this.checkboxTarget.classList.add("d-none")
      input.checked  = false
      input.disabled = true
    }
  }
}
