import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  toggleLine(event) {
    let noButtonClicked = !event.target.classList.contains('actionable') && !event.target.parentElement.classList.contains('actionable')
    if (noButtonClicked) {
      const demandId = this.element.dataset.id
      const demand = document.getElementById(`${demandId}`);
      demand.classList.toggle('hide');
    }
  }

  toggleEdit() {
    const fullForm = this.targets.find('full')
    const editForm = this.targets.find('edit')
    fullForm.classList.toggle('hide');
    editForm.classList.toggle('hide');
  }
}
