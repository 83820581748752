import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['button', 'btnTxt', 'caret', 'dropdown', 'dropdownItem', 'searchForm', 'searchParams']
  initialize() {
    window.addEventListener('click', e => {
      if (!this.buttonTarget.contains(e.target) && !this.dropdownTarget.classList.contains('d-none')) {
        this.dropdownTarget.classList.add('d-none')
        this.caretTarget.classList.remove('rotate180')
      }
    })
    this.setSearchParams()
  }

  toggleDropdown() {
    this.dropdownTarget.classList.toggle('d-none')
    this.caretTarget.classList.toggle('rotate180')
  }

  setSearch(e) {
    const selectedSearch = e.currentTarget
    this.searchFormTarget.action = selectedSearch.dataset.path
    this.btnTxtTarget.innerText  = selectedSearch.dataset.text
    this.setSearchParams()
  }

  launchSearch() {
    this.searchFormTarget.submit()
  }

  setSearchParams() {
    this.searchParamsTarget.innerHTML = ''
    const searchUrl    = new URL(this.searchFormTarget.action)
    const searchParams = Object.fromEntries(searchUrl.searchParams.entries())
    Object.keys(searchParams).forEach(key => {
      const input = document.createElement("input")
      input.name  = key
      input.value = searchParams[key]
      input.type  = 'hidden'
      this.searchParamsTarget.appendChild(input)
    })
  }

}
