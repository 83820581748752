import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "progress", "flash" ]

  countdown = 5000
  updates   = 1000

  initialize() {
    this.decrease = this.decrease.bind(this)
    this.clear    = this.clear.bind(this)
  }

  connect() {
    if (!this.hasProgressTarget) return

    this.fullWidth  = this.progressTarget.offsetWidth
    this.percentage = 100
    this.interval   = setInterval(this.decrease, this.countdown / this.updates)
    this.timeout    = setTimeout(this.clear, this.countdown)
  }

  decrease() {
    if (this.hasProgressTarget) {
      this.percentage = this.percentage - (100 / this.updates)
      const width = this.fullWidth * this.percentage / 100
      this.progressTarget.style.width = `${width}px`
    }
  }

  clear() {
    this.flashTarget.click()
  }

  disconnect() {
    clearInterval(this.interval)
    clearTimeout(this.timeout)
  }
}
