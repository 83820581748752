import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['disable', 'commit']

  connect() {
    const form = this.commitTarget.closest("form")
    form.addEventListener("submit", this.submit.bind(this))
  }

  submit(e) {
    if(this.commitTarget.value == "preview") return

    const spinner = '<div id="quotes-spinner"></div>'
    this.disableTargets.forEach(target => {
      target.innerHTML = spinner
      target.disabled  = true
    })
  }

  preview(e) { this.commitTarget.value = "preview" }
  save(e)    { this.commitTarget.value = "save" }


  generate(e) {
    this.commitTarget.value = "generate"

    const dateInput     = document.getElementById("invoice_creation_date")
    const selectedDate = moment(dateInput.value, "DD/MM/YYYY")
    const lastDate     = moment(this.data.get("reference"), "YYYY-MM-DD")

    const closingMonth = selectedDate > lastDate && selectedDate.month() != lastDate.month()

    if(!closingMonth) return

    if(!confirm(I18n.t("invoices.close_month_warning"))) e.preventDefault()
  }

}
