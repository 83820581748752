// <div data-controller="components--switch-button">
//   <h1 data-target="components--switch-button.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    this.fetchParams = {
      credentials: 'same-origin',
      method: 'PATCH',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }
  }

  toggle() {
    const confirm_message = this.data.get('confirmation')
    if (confirm_message) {
      const activeEl = this.element.checked
      if (activeEl && !confirm(confirm_message)) {
        this.element.checked = false
        return false
      }
    }

    fetch(this.data.get('route'), this.fetchParams)
    .then(response => response.text())
    .then(str => eval(str))
  }
}
