import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ["branch", "icon"]

  toggleBranches(e) {
    this.branchTargets.forEach(branch => branch.classList.toggle('d-none'))
    this.iconTargets.forEach(icon => icon.classList.toggle('d-none'))
  }

  expandAllBranches(e) {
    const isExpanded = e.target.classList.contains("fa-folder-open");

    // Toggle the folder icon between open and closed
    e.target.classList.toggle("fa-folder-open", !isExpanded);
    e.target.classList.toggle("fa-folder", isExpanded);

    document.querySelectorAll("div[data-target='materials--tree.branch']").forEach(branch => {
      if (isExpanded) {
        branch.classList.add('d-none');  // Fold all branches
      } else {
        branch.classList.remove('d-none');  // Unfold all branches
      }
    });

    document.querySelectorAll("i.fa-plus-square").forEach(icon => {
      icon.classList.toggle('d-none', !isExpanded); // Show plus icon when folded
    });
    document.querySelectorAll("i.fa-minus-square").forEach(icon => {
      icon.classList.toggle('d-none', isExpanded); // Show minus icon when unfolded
    });
  }

}
