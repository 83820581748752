import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'newForm', 'appendHere' ]

  setOrder() {
    const orderGoals = document.querySelectorAll('.order-goals')
    orderGoals.forEach((order, index) => order.value = index + 1 )
  }

  remove(e) {
    e.preventDefault()
    e.currentTarget.closest('.element-to-delete').remove()
  }

  clone(e) {
    e.preventDefault()

    let newFieldString = this.newFormTarget.innerHTML
    newFieldString = newFieldString.replace(/extra_field_element_id/gi, Date.now())
    const newField = this._createElementFromHTML(newFieldString)

    newField.querySelectorAll('.checkbox-input').forEach((input) => {
        const newId = input.id + '_' + Date.now()
        input.id = newId
        const inputLabel = input.parentNode.querySelector('label')
        inputLabel.htmlFor = newId
    })
    newField.classList.remove("hidden");
    document.getElementById('parentForSortable').insertBefore(newField, this.appendHereTarget)
  }

  _createElementFromHTML(htmlString) {
    const div = document.createElement('div')
    div.innerHTML = htmlString.trim()
    return div.firstChild
  }
}
