import { Controller } from 'stimulus'
import { getRequest } from  '@react/helpers/fetch'
import { pathname }   from  '@react/helpers/fetch'

export default class extends Controller {
  static targets = ["fields", "select"]

  change() {
    const category = this.selectTarget.value
    if (category) {
      const path   = this.data.get("path")
      const params = { id: category }
      const format = "json"
      const url    = pathname({ path, params, format })

      getRequest(url)
      .then(response => this.fieldsTarget.innerHTML = response.html)
    } else {
      this.fieldsTarget.innerHTML = ""
    }
  }
}
