import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['radio']

  reset(e) {
    e.preventDefault()
    this.radioTargets.forEach(radio => radio.checked = false)
  }
}
