import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['box']

  connect() {
    if(!this.hasBoxTarget) return

    this.hideExportNotice()
    this.element.querySelectorAll("input[type='radio']").forEach(radio => {
      if(radio.value === "pdf") {
        radio.addEventListener("click", this.showExportNotice.bind(this))
      } else {
        radio.addEventListener("click", this.hideExportNotice.bind(this))
      }
    })
  }

  showExportNotice() {
    this.boxTarget.classList.remove('d-none');
  }

  hideExportNotice() {
    this.boxTarget.classList.add('d-none');
  }
}
