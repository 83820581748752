import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = []

  connect() {
  }

  purge_file(e) {
    e.preventDefault()
    this._setValues(e.currentTarget)
    if (confirm(I18n.t('mobile.files.delete', { file: this.filename }))) {
      this.container = e.currentTarget.parentElement.parentElement
      this.container.style.opacity = '0.5'
      fetch(this.url, {
        method: 'DELETE',
        ...this.fetchParams,
      }).then(response => this._useResponse(response))
    } else {
      return
    }
  }

  _setValues(el) {
    this.file_id  = el.dataset.fileid
    this.filename = el.dataset.filename
    this.url      = el.dataset.url
  }

  _useResponse(response) {
    if (response.status > 299) {
      this.container.classList.add('image_grid__block--error')
      this.container.style.opacity = '1'
    } else {
      this.container.remove()
    }
  }

  fetchParams = {
    credentials: 'same-origin',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
  }
}
