import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    this.refresh  = this.refresh.bind(this)
    this.interval = parseInt(this.data.get("interval")) || 1000
  }

  connect() {
    this.timeout = setTimeout(this.refresh, this.interval)
  }

  disconnect() {
    clearTimeout(this.timeout)
  }

  refresh() {
    this.element?.click()
  }
}
