// data-controller="maintenance--plans--toggle-task"
// data-action="maintenance--plans--toggle-task#action"
// data-target="maintenance--plans--toggle-task.target"
// data-maintenance--plans--toggle-task-data="data" this.data.get('data')

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['hidden'];

  toggle() {
    const taskId = this.data.get("id")
    const hiddenRows = document.querySelectorAll(`.task${taskId}-details`)
    hiddenRows.forEach(this._toggleRow)
  }

  _toggleRow(row) {
    const show = row.style.display == 'none' || row.style.display == ''
    row.style.display = show ? 'contents' : 'none'
  }
}
