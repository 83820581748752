import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["lineCheckbox", "globalCheckbox"]

  toggleAll() {
    const isChecked = this.globalCheckboxTarget.checked

    this.lineCheckboxTargets.forEach(checkbox => {
      checkbox.checked = isChecked
      checkbox.dispatchEvent(new Event('change'))
    })
  }
}
