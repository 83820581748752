// sendClientLedger_controller.js

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'emailFormInput', 'emailFormContainer',
                     'subject1', 'subject2', 'subject3',
                     'message1', 'message2', 'message3'
                   ]

  reminderLevelChange(e) {
    this.emailFormInputTargets.forEach( e => { e.classList.add('d-none') })
    switch (e.currentTarget.value) {
      case '1':
        this.subject1Target.classList.remove('d-none');
        this.message1Target.classList.remove('d-none');
        break;
      case '2':
        this.subject2Target.classList.remove('d-none');
        this.message2Target.classList.remove('d-none');
        break;
      case '3':
        this.subject3Target.classList.remove('d-none');
        this.message3Target.classList.remove('d-none');
        break;
    }
  }

  send() {
    this.emailFormContainerTarget.innerHTML = '<div class="loading-spinner-container"><div id="loading-spinner"></div></div>';
  }
}
