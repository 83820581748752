import { Controller } from 'stimulus'
import { getRequest } from '@react/helpers/fetch'

export default class extends Controller {

  static targets = [
    'technicianId',
    'datePlanned',
    'predictedDuration',
    'statusMessage',
    'technicianInformation'
  ]

  checkDates() {
    this._hideStatusMessage()
    if (!this.technicianIdTarget.value) return
    const external = this.technicianIdTarget.selectedOptions[0].dataset.external
    if (external == "true") return

    const technicianId      = this.technicianIdTarget.value
    const datePlanned       = this.datePlannedTarget.value
    const predictedDuration = this.predictedDurationTarget.value
    if(!technicianId || !datePlanned || !predictedDuration) return

    const url    = new URL(window.location.origin)
    url.pathname = "/workorders/form/tech_availability"
    url.searchParams.set("technician",         technicianId)
    url.searchParams.set("date_planned",       datePlanned)
    url.searchParams.set("predicted_duration", predictedDuration)

    getRequest(url.pathname + url.search).then(calendarResponse => {
      this._showStatusMessage()
      this.statusMessageTarget.textContent = calendarResponse.message
      this._setMessageStyling(calendarResponse.available)
    })
  }

  showTechnicianInformation() {
    this._hideTechnicianInformation()
    const selectedOption = this.technicianIdTarget.selectedOptions[0]
    if (!selectedOption) return

    this.technicianInformationTarget.innerHTML = selectedOption.dataset.information
    this._showTechnicianInformation()
  }


  // Private

  _hideStatusMessage() {
    this.statusMessageTarget.classList.add('d-none')
  }

  _hideTechnicianInformation() {
    this.technicianInformationTarget.classList.add('d-none')
  }

  _showStatusMessage() {
    this.statusMessageTarget.classList.remove('d-none')
  }

  _showTechnicianInformation() {
    this.technicianInformationTarget.classList.remove('d-none')
  }

  _setMessageStyling(available) {
    if (available) {
      this.statusMessageTarget.classList.remove('busy')
    } else {
      this.statusMessageTarget.classList.add('busy')
    }
  }
}
