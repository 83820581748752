import { Controller } from 'stimulus'
import SignaturePad from 'signature_pad'

export default class extends Controller {
  static targets = ['canvas', 'input', 'pad', 'existing']

  connect() {
    this.initializeSignaturePad()
    this.resizeCanvas()
    this.signaturePad.off()
    this.canvasTarget.dataset.status = 'off'
    this.cachedWidth = this.canvasTarget.offsetWidth

    if (this.inputTarget.value) {
      this.signaturePad.fromDataURL(this.inputTarget.value)
    }
  }

  initializeSignaturePad() {
    const updateInput = () => {
      this.inputTarget.value = this.signaturePad.toDataURL()
    }

    this.signaturePad = new SignaturePad(this.canvasTarget, {
      backgroundColor: 'rgb(255, 255, 255)',
      penColor: 'rgb(0, 0, 0)',
      onEnd: updateInput
    })
  }

  activatePad(event) {
    switch (this.canvasTarget.dataset.status) {
      case 'off':
        this.canvasTarget.dataset.status = 'on'
        this.signaturePad.on()
        event.currentTarget.innerHTML = this.canvasTarget.dataset.disabletext
        break
      case 'on':
        this.canvasTarget.dataset.status = 'off'
        this.signaturePad.off()
        event.target.innerText = this.canvasTarget.dataset.activatetext
        break
    }
  }

  clearPad() {
    this.signaturePad.clear()
    this.inputTarget.value = ''
  }

  resizeCanvas() {
    if (this.canvasTarget.offsetWidth !== this.cachedWidth) {
      this.cachedWidth = this.canvasTarget.offsetWidth
      const ratio = Math.max(window.devicePixelRatio || 1, 1)
      this.canvasTarget.width = this.canvasTarget.offsetWidth * ratio
      this.canvasTarget.height = this.canvasTarget.offsetHeight * ratio
      this.canvasTarget.getContext('2d').scale(ratio, ratio)
    }
  }

  togglePad() {
    this.padTarget.classList.remove('d-none')
    this.inputTarget.disabled = false
    this.existingTarget.remove()
    this.connect()
  }
}