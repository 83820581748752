import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["quantityInput", "lineCheckbox"]

  toggleQuantity(event) {
    const isChecked = this.lineCheckboxTarget.checked
    const maxQuantity = parseFloat(this.lineCheckboxTarget.dataset.maxQuantity).toFixed(2)

    if (isChecked) {
      this.quantityInputTarget.value = maxQuantity
    } else {
      this.quantityInputTarget.value = ""
    }

    this.quantityInputTarget.dispatchEvent(new Event('keyup'))
  }

  check100Percent() {
    const quantityInputValue = parseFloat(this.quantityInputTarget.value).toFixed(2)
    const maxQuantity = parseFloat(this.lineCheckboxTarget.dataset.maxQuantity).toFixed(2)
    if (quantityInputValue === maxQuantity) {
      this.lineCheckboxTarget.checked = true
    } else {
      this.lineCheckboxTarget.checked = false
    }
  }
}
