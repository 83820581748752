import { Controller } from 'stimulus'
import { jsRequest } from "@react/helpers/fetch"

export default class extends Controller {
  static targets = ['site', 'task']

  connect() {
    if (this.hasSiteTarget) {
      const change = new Event("change")
      this.siteTarget.dispatchEvent(change)
    }
  }

  selectSite(e) {
    const siteId = e.target.value
    if(!siteId) return

    const url = new URL(this.data.get('siteUrl'))
    url.searchParams.set("site_id", siteId)
    jsRequest(url)
  }

  selectTask() {
    const taskBlueprintId = parseInt(this.taskTarget.value)
    if(!taskBlueprintId) return

    const url = new URL(this.data.get('taskUrl'))
    url.searchParams.set("task_blueprint", taskBlueprintId)
    jsRequest(url)
  }
}
