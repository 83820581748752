import { Controller } from 'stimulus'
import { jsRequest } from '@react/helpers/fetch'

export default class extends Controller {
  static targets = [ 'modal' ]

  show(event) {
    const link  = event.target.closest('[data-route]')
    const route = link.dataset.route
    this.load()

    jsRequest(route)
  }

  load() {
      const spinnerHTML =
    `
      <div class="loading-spinner-container">
        <div id="loading-spinner"></div>
      </div>
    `;
      this.modalTarget.innerHTML = spinnerHTML;
  }
}
