import { Controller } from 'stimulus'

const INTERVAL = "interval"

export default class extends Controller {
  static targets = ["select", "value", "step"]

  connect() { this.onChange() }

  onChange() {
    const condition = this.selectTarget.value
    if (condition == INTERVAL) {
      this.stepTarget.disabled     = false
      this.valueTarget.placeholder = I18n.t("workflows.reference")
      this.stepTarget.classList.remove("d-none")
    } else {
      this.stepTarget.disabled     = true
      this.valueTarget.placeholder = this.data.get("placeholder")
      this.stepTarget.classList.add("d-none")
    }
  }
}
