import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["name", "bg_color", "text_color", "badge"]

  connect() {
    this.update()
  }

  update(e) {
    this.badgeTarget.innerHTML             = e?.currentTarget?.value || this.nameTarget.value
    this.badgeTarget.style.backgroundColor = this.bg_colorTarget.value
    this.badgeTarget.style.color           = this.text_colorTarget.value
  }
}
