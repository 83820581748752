import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['billingDetails', 'billingDetailsModal']

  toggleBillingDetailsSection() {
      this.billingDetailsTarget.classList.toggle('d-none');
  }

  toggleBillingDetailsSectionModal() {
      this.billingDetailsModalTarget.classList.toggle('d-none');
    }
}
