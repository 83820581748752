import { Controller }  from 'stimulus'
import { getRequest }  from "@react/helpers/fetch"
import { pathname }    from "@react/helpers/fetch"
import { postRequest } from "@react/helpers/fetch"
import   CableReady    from 'cable_ready'

export default class extends Controller {

  initialize() {
    this._connected = this._connected.bind(this)
    this._received  = this._received.bind(this)
    this._fail      = this._fail.bind(this)
    this.channel    = "RenderLaterChannel"
    this.uuid       = this.data.get("uuid")
    this.params     = { channel: this.channel, uuid: this.uuid }

    this.element.dataset.uuid = this.uuid
  }

  connect() {
    this.subscription = App.cable.subscriptions.create(this.params, {
      connected: this._connected,
      received:  this._received
    })
  }

  disconnect() {
    this.subscription.unsubscribe()
  }

  _connected() {
    const path   = this.data.get("path")
    const format = 'json'
    const params = { uuid: this.uuid }
    const url    = pathname({ path, params, format })
    const body   = JSON.parse(this.data.get("body"))
    postRequest({ url, body })
  }

  _received(data) {
    if (data.cableReady) {
      if (data.operations.consoleLog) {
        this._fail(data.operations.consoleLog[0])
      } else {
        CableReady.perform(data.operations)
      }
    }
  }

  _fail(info) {
    const message = Object.values(info)[0]
    info.alert ? flashAlert(message) : flashNotice(message)
    this.element.remove()
  }
}
