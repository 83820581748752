import Dropzone                        from "dropzone"
import { Controller }                  from "stimulus"
import { DirectUploadController }      from "./direct_upload"
import { getMetaValue, removeElement } from "../../application/helpers/helpers"

const createDirectUploadController = (controller, file, form) => {
    new DirectUploadController(controller, file, form).start()
}

const createDropZone = (controller) => {
  if (Dropzone.instances.length > 0) {
    Dropzone.instances.forEach((e) => {
      e.off()
      e.destroy()
    })
  }

  return new Dropzone(controller.element, {
    url:            controller.url,
    headers:        controller.headers,
    maxFiles:       controller.maxFiles,
    maxFilesize:    controller.maxFileSize,
    acceptedFiles:  controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    dictRemoveFile: I18n.t('mobile.files.remove'),
    autoQueue:      false
  })
}

export default class extends Controller {
  static targets = ["input"]

  initialize() {
    Dropzone.autoDiscover = false // necessary quirk for Dropzone error in console
    this.dropZone = createDropZone(this)
    this.hideFileInput()
    this.bindEvents()
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled      = true
    this.inputTarget.style.display = "none"
  }

  bindEvents() {
    const form = this.element.closest('form')
    this.dropZone.on("addedfile", file => {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file, form)
      }, 500)
    })

    this.dropZone.on("removedfile", file => {
      file.controller && removeElement(file.controller.hiddenInput)
    })

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort()
    })
  }

  get headers()        { return { "X-CSRF-Token": getMetaValue("csrf-token") } }
  get url()            { return this.inputTarget.getAttribute("data-direct-upload-url") }

  get maxFiles()       { return this.data.get("maxFiles") || 1 }
  get maxFileSize()    { return this.data.get("maxFileSize") || 256 }

  get acceptedFiles()  { return this.data.get("acceptedFiles") }
  get addRemoveLinks() { return this.data.get("addRemoveLinks") || true }
}
