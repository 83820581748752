import { DirectUpload } from "@rails/activestorage"

const PATH = "/rails/active_storage/direct_uploads.json"
export const directUpload = file => {
  return new Promise((resolve, reject) => {
    const url      = new URL(window.location.origin + PATH)
    const uploader = new DirectUpload(file, url.href)
    uploader.create((error, attributes) => {
      if (error) {
        reject(error)
      } else {
        resolve(attributes.signed_id)
      }
    })
  })
}
