import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["select", "section"]

  connect() {
    this.setSelected()
  }

  setSelected() {
    const value = this.selectTarget.value

    this.sectionTargets.forEach(section => {
      if(section.dataset.table == value) {
        section.classList.remove("d-none")
      } else {
        section.classList.add("d-none")
      }
    })
  }
}
