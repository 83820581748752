// <div data-controller="workorders--demands--photos">
//   <h1 data-target="workorders--demands--photos.output"></h1>
//   <h1 data-action="workorders--demands--photos#action"></h1>
// </div>
import Compressor from 'compressorjs';
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'container', 'input', 'button' ]

  upload(e) {
    this._createSpinner()
    this.buttonTarget.classList.add("d-none")
    const controller = this
    const file = this.inputTarget.files[0];
    new Compressor(file, {
      quality: 0.6,
      maxHeight: 1024,
      maxWidth: 1024,
      success(result) {
        controller._createPhotoContainer()

        controller._createImageContainer()
        controller._appendImageToImageContainer(result)
        controller._appendRemovePhotoButtonToImageContainer()
        controller._appendImageContainerToPhotoContainer()

        controller._appendInputToPhotoContainer(result)
      },
      error(err) {
        console.error(err.message)
        controller._destroySpinners()
      }
    })
  }

  click() {
    this.inputTarget.click()
  }

  remove(e) {
    const photo = e.target.closest('.photo-container')
    photo.remove()
    this.buttonTarget.classList.remove("d-none")
  }

  // Private Methods

  _createSpinner() {
    const spinner = document.createElement('div')
    spinner.classList.add('spinner')
    this.containerTarget.appendChild(spinner)
  }

  _createPhotoContainer() {
    this.photoContainer = document.createElement('div')
    this.photoContainer.classList.add('photo-container')
    this.photoContainer.classList.add('mt-2')
  }

  // Image container

  _createImageContainer() {
    this.imageContainer = document.createElement('div')
    this.imageContainer.classList.add("photo-element")
  }

  _appendImageToImageContainer(result) {
    const image = document.createElement('img')
    image.src = window.URL.createObjectURL(result)
    image.classList.add('demand-thubmnail')
    this.imageContainer.appendChild(image)
  }

  _appendRemovePhotoButtonToImageContainer() {
    const removeButton = document.createElement('div')
    removeButton.classList.add('remove-photo-button')
    removeButton.innerHTML = '<i class="fas fa-times"></i>'
    removeButton.addEventListener('click', this.remove.bind(this))
    this.imageContainer.appendChild(removeButton)
  }

  _appendImageContainerToPhotoContainer() {
    this.photoContainer.appendChild(this.imageContainer)
  }

  // Input Container
  _appendInputToPhotoContainer(result) {
    const controller  = this
    const inputName   = this.data.get('inputName')
    const reader      = new FileReader();
    reader.readAsDataURL(result);
    reader.onload = () => {
      const base64data = reader.result;
      const input   = document.createElement('input')
      input.type    = 'hidden'
      input.name    = inputName
      input.value   = base64data
      controller.inputTarget.value = ""
      controller.photoContainer.appendChild(input)
      controller.containerTarget.appendChild(this.photoContainer)
      controller._destroySpinners()
    }
  }

  _destroySpinners() {
    document.querySelectorAll('.spinner').forEach((spinner) => {
      spinner.remove()
    })
  }
}
