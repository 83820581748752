import { Controller } from 'stimulus'
import QRCode from 'qrcode'

export default class extends Controller {
  connect() {
    QRCode.toDataURL(this.data.get("code"))
      .then(this._displayQrCode.bind(this))
      .catch(err => console.error(err))
  }

  _displayQrCode(url) {
    const image = document.createElement('img')
    const imgSize = this.data.get("size")
    image.src = url
    image.style.width = imgSize
    this.element.innerHTML = ''
    this.element.appendChild(image)
  }
}
