
/* Example of use in HTML

<div data-controller="autocomplete">
  <input data-target="autocomplete.answerId" style="display: none;" value="" name="workorder[requester_id]">
  <div data-target="autocomplete.answers" value="<%= @account.technicians.order(:email).collect{ |u| { name: u.email,id: u.id } }.to_json %>">
    <input data-target="autocomplete.input_field" type="text" placeholder="requester">
  </div>
</div>

*/

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'answerId', 'answers', 'input_field']

  connect() {
      const data  = JSON.parse(this.answersTarget.getAttribute('value'))
      var array   = []
      data.forEach((single_data) => { array.push([single_data.name, single_data.id]) })
      this.autocomplete(this.input_fieldTarget,array)
  }

  autocomplete(inp, arr) {
      /* the autocomplete function takes two arguments,
      the text field element and an array of possible autocompleted values:*/
      var currentFocus
      const controller = this

      inp.addEventListener('focus', () => {
          if( document.getElementById('id', this.id + 'autocomplete-list') == null ) {
              a = document.createElement('DIV')
              a.setAttribute('id', this.id + 'autocomplete-list')
              a.setAttribute('class', 'autocomplete-items')

              /* Append the DIV element as a child of the autocomplete container : */
              this.parentNode.appendChild(a)
              var a, b, i, val = ''

              for (i = 0; i < arr.length; i++) {
                  /* Create a DIV element for each matching element : */
                  b = document.createElement('DIV')

                  /* Make the matching letters bold : */
                  b.innerHTML = '<strong>' + arr[i][0].substr(0, val.length) + '</strong>'
                  b.innerHTML += arr[i][0].substr(val.length)

                  /* Insert a input field that will hold the current array item's value : */
                  b.innerHTML += '<input data-name=\'' + arr[i][0] + '\' type=\'hidden\' value=\'' + arr[i][1] + '\'>'

                  /* Execute a function when someone clicks on the item value (DIV element)
                  TODO : These variables aren't used are they ? value and text */

                  var value = arr[i][0]
                  const text = arr[i][1]
                  b.addEventListener('click', function(e) {
                      /* Insert the value for the autocomplete text field : */
                      controller.answerIdTarget.value = this.getElementsByTagName('input')[0].value
                      inp.value =  this.getElementsByTagName('input')[0].dataset.name
                      /* Close the list of autocompleted values,
                        (or any other open lists of autocompleted values : */
                      closeAllLists()
                  })
                  a.appendChild(b)
              }
          }
      })

      /* Execute a function when someone writes in the text field : */
      inp.addEventListener('input', function(e) {
          var a, b, i, val = this.value
          /* Close any already open lists of autocompleted values */
          closeAllLists()

          if (!val) { return false}
          currentFocus = -1
          /* Create a DIV element that will contain the items (values) : */
          a = document.createElement('DIV')
          a.setAttribute('id', this.id + 'autocomplete-list')
          a.setAttribute('class', 'autocomplete-items')
          /* Append the DIV element as a child of the autocomplete container: */
          this.parentNode.appendChild(a)
          /* For each item in the array...*/

          for (i = 0; i < arr.length; i++) {
              /* Check if the item starts with the same letters as the text field value : */
              if (arr[i][0].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                  /* Create a DIV element for each matching element : */
                  b = document.createElement('DIV')
                  /* Make the matching letters bold : */
                  b.innerHTML = '<strong>' + arr[i][0].substr(0, val.length) + '</strong>'
                  b.innerHTML += arr[i][0].substr(val.length)
                  /* Insert a input field that will hold the current array item's value : */
                  b.innerHTML += '<input type=\'hidden\' value=\'' + arr[i][1] + '\'>'
                  /* Execute a function when someone clicks on the item value (DIV element) : */
                  var value = arr[i][0]
                  b.addEventListener('click', function(e) {
                      /* Insert the value for the autocomplete text field : */
                      controller.answerIdTarget.value = this.getElementsByTagName('input')[0].value
                      inp.value = value
                      /* Close the list of autocompleted values,
                        (or any other open lists of autocompleted values : */
                      closeAllLists()
                  })
                  a.appendChild(b)
              }
          }
      })
      /* Execute a function presses a key on the keyboard : */
      inp.addEventListener('keydown', function(e) {
          var x = document.getElementById(this.id + 'autocomplete-list')
          if (x) x = x.getElementsByTagName('div')
          if (e.keyCode == 40) {
              /* If the arrow DOWN key is pressed,
                increase the currentFocus variable : */
              currentFocus++
              /* And and make the current item more visible : */
              addActive(x)
          } else if (e.keyCode == 38) { //up
              /* If the arrow UP key is pressed,
                decrease the currentFocus variable : */
              currentFocus--
              /* and and make the current item more visible : */
              addActive(x)
          } else if (e.keyCode == 13) {
              /*If the ENTER key is pressed, prevent the form from being submitted,*/
              e.preventDefault()
              if (currentFocus > -1) {
                  /* And simulate a click on the "active" item : */
                  if (x) x[currentFocus].click()
              }
          }
      })

      function addActive(x) {
          /* A function to classify an item as "active" : */
          if (!x) return false
          /* Start by removing the "active" class on all items : */
          removeActive(x)
          if (currentFocus >= x.length) currentFocus = 0
          if (currentFocus < 0) currentFocus = (x.length - 1)
          /* Add class "autocomplete-active" : */
          x[currentFocus].classList.add('autocomplete-active')
      }

      function removeActive(x) {
          /* A function to remove the "active" class from all autocomplete items : */
          for (var i = 0; i < x.length; i++) { x[i].classList.remove('autocomplete-active') }
      }

      function closeAllLists(elmnt) {
      /* Close all autocomplete lists in the document,
          except the one passed as an argument : */
          var x = document.getElementsByClassName('autocomplete-items')
          for (var i = 0; i < x.length; i++) {
              if (elmnt != x[i] && elmnt != inp) {
                  x[i].parentNode.removeChild(x[i])
              }
          }
          /* Execute a function when someone clicks in the document : */
          document.addEventListener('click', function (e) { closeAllLists(e.target) })
      }
  }
}

