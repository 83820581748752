import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['prefix', 'number', 'code', 'lock']

    connect() {
        this.setCode()
    }

    setCode() {
        const prefix = this.prefixTarget.value
        const number = this.numberTarget.value
        const code   = this._parseNextCode(prefix, number)

        this.codeTarget.value = code
    }

    unlock() {
      this.numberTarget.disabled = false
      this.lockTarget.classList.add('d-none')
    }

    // Private Methods

    _parseNextCode(prefix, number) {
        if (!prefix) {
            prefix = ''
        }
        const code = prefix + this._leading_zeros(number)
        return code
    }

    _leading_zeros(num) {
        num = parseInt(num) + 1
        let s = num
        while (s.length < 4) s = '0' + s
        return s
    }
}
