import { Controller } from "stimulus"

const PRODUCT = 'product'
const SERIAL  = 'serial_numbers'
const SERVICE = 'service'

export default class extends Controller {
  static targets = [ 'select', 'stock', 'stockContainer', 'serial', 'serialContainer' ]

  initialize() {
    this.selectProduct  = this.selectProduct.bind(this)
    this.selectSerial   = this.selectSerial.bind(this)
    this.selectService  = this.selectService.bind(this)
  }

  connect() {
    this.select()
  }

  select() {
    switch(this.selectTarget.value) {
      case PRODUCT : return this.selectProduct()
      case SERIAL  : return this.selectSerial()
      case SERVICE : return this.selectService()
    }
  }

  selectProduct() {
    this.stockContainerTarget.classList.remove("d-none")
    this.serialContainerTarget.classList.add('d-none')
    this.serialTarget.value = ''
  }

  selectSerial() {
    this.stockContainerTarget.classList.add("d-none")
    this.serialContainerTarget.classList.remove('d-none')
    this.stockTargets.forEach(input => input.value = '')
  }

  selectService() {
    this.stockContainerTarget.classList.add("d-none")
    this.serialContainerTarget.classList.add('d-none')
    this.serialTarget.value = ''
    this.stockTargets.forEach(input => input.value = '')
  }
}
