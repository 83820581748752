import { Controller } from "stimulus"
import { roundMoney } from "@react/helpers/helpers"

export default class extends Controller {

    static targets = [ "quantitySection", "travelPreference", "travelQuantity", "totalCost" ]

    handleTravelPreferenceSelect() {
      const isFixedPrice = this.travelPreferenceTarget.selectedOptions[0].dataset.isFixedPrice
      if (isFixedPrice == 'false') {
        this.calculateTravelCost()
        this._show()
      } else {
        this._hide()
        this._setFixedQuantity()
      }
    }

    calculateTravelCost() {
        const price = this.travelPreferenceTarget.selectedOptions[0].dataset.price
        const quantity = parseFloat(this.travelQuantityTarget.value) || 0
        const totalCost = quantity * price
        this.totalCostTarget.value = roundMoney(totalCost)
    }


    // private

    _show() {
      this.quantitySectionTarget.classList.remove('d-none')
    }

    _hide() {
      this.quantitySectionTarget.classList.add('d-none')
    }

    _setFixedQuantity() {
      this.travelQuantityTarget.value = 1;
    }
}
