import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'manageFormsList' ]

  spin() {
    this._showSpinner()
  }

  // Private methods

  _showSpinner() {
      const manageFormsList = this.manageFormsListTarget
      const spinnerHTML =
    ` <div class="loading-spinner-container-sm">
        <div id="loading-spinner"></div>
      </div>
    `
      manageFormsList.innerHTML = spinnerHTML
  }
}
