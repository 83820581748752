import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['hidden']

  connect() {
    this.toggle()
  }

  toggle() {
    this.hiddenTargets.forEach((target) => {
      target.classList.toggle("d-none")
    })
  }
}
