import { Controller } from "stimulus"
import Awesomplete    from 'awesomplete'

export default class extends Controller {
  static targets = [ "input" ]

  initialize() {
    this.list    = JSON.parse(this.data.get('list'))
    this.replace = this.replace.bind(this)
  }

  connect() {
    if (this.list.length == 0) return

    this.awesomplete = new Awesomplete(this.inputTarget, {
      minChars: 0,
      maxItems: 500,
      list:     this.list,
      replace:  this.replace
    })
  }

  click() {
    if (this.list.length == 0) return

    if (this.awesomplete.ul.childNodes.length === 0) {
      this.awesomplete.evaluate()
    }
    else if (this.awesomplete.ul.hasAttribute('hidden')) {
      this.awesomplete.open()
    }
    else {
      this.awesomplete.close()
    }
  }

  replace(suggestion) {
    this.inputTarget.value = suggestion.label
    this.awesomplete.close()
  }
}
