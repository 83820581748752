import { Controller } from 'stimulus'

export default class extends Controller {
  clear() {
    const container = this.element.parentNode
    container.querySelectorAll(".flatpickr-input").forEach(input => {
      input.value = ""
    })
  }
}
