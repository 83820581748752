import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'output', 'origin' ]

  reveal(event) {
      if (this.outputTarget.classList.contains('hide')) {
          this.outputTarget.classList.remove('hide')
          this.originTarget.classList.add('hide')
      } else {
          this.outputTarget.classList.add('hide')
          this.originTarget.classList.remove('hide')
      }
  }
}
