import { Controller }                       from "stimulus"
import { getRequest, jsRequest, pathname }  from '@react/helpers/fetch'


export default class extends Controller {
  static targets = [ "dropdown", "assignUsersFields" ]

  // Upon a change in the dropdown calendar list selection --> call rails app (accounts#refresh_google_calendar_users_list),
  // which will:
  //        1. assign new values to @calendar_users
  //        2. replace the content of the google calendar users form -->
  //                re-render the content inside the modal

  connect() {
    if (this.data.get('openModal') === 'true') {
      this.element.querySelector('#openCalendarModalButton').click()
    }
  }

  selectCalendar(event) {
    const selectedCalendarId = this.dropdownTarget.value

    const spinnerHTML = `<div class="loading-spinner-container-sm h-25vh"><div id="loading-spinner"></div></div>`
    this.assignUsersFieldsTarget.innerHTML = spinnerHTML

    // call controller accounts#refresh_google_calendar_users_list
    const path   = this.data.get('route')
    const params = { calendar_id: selectedCalendarId }
    const url    = pathname({ path, params })
    jsRequest(url)

  }
}
